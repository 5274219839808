/* JS MOBILE */

// For configurator workshop
function alertV2(str) {
    let albox = $('.configurator_alert_box');
    let shade = $('.configurator_shade');

    $('body').addClass('alert_open');

    $('.txt_alert', albox).html(str);

    $(".close", albox).on("click", closeAlertBox);
    $(".close_abox", albox).on("click", closeAlertBox);
    $(shade).one("click", closeAlertBox);
    $(shade).addClass('actif');

    $(albox).fadeIn(400);
}

function closeAlertBox() {
    let albox = $('.configurator_alert_box');
    let shade = $('.configurator_shade');

    $(albox).fadeOut(400);

    $(shade).removeClass('actif');
    $(shade).off("click", closeAlertBox);
    $('body').removeClass('alert_open');
}

// Smoothly scrolls back to top
function backToTop() {

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

    $("html, body").animate({
        scrollTop: 0
    }, (speed * 2), function () {});
}

// Update cart
function onCartUpdate(response) {

    var cart_btn, modbox = 'item_error';
    var res = '';

    try {
        res = JSON.parse(response);
    } catch (e) {
        res = response;
    }

    if (!res.error) {
        response = response.trim().split("//");
        var is_achat_express = false;

        if (response[0] != false && response.length >= 0) {
            cart_btn = document.getElementById('cart_btn');
            var is_achat_express =  this.data.is_achat_express_input;
            if (/^\d/.test(this.data.name)) {
                if (response[0] == 1) {
                    response[0] += " lot de";
                } else {
                    response[0] += " lots de";
                }
            }

            response[0] += ' ' + this.data.name;

            if (this.data.size !== 'none') {
                response[0] += ' - Taille ' + (this.data.lbl_size || this.data.last_size);
            }

            if (this.data.colour !== 'none') {
                response[0] += ' - ' + (this.data.lbl_colour || this.data.last_colour);
            }

            if (response[1] == 0) {
                if (cart_btn) {
                    cart_btn.classList.add('none');
                    cart_btn.setAttribute('data-cart', response[1]);
                }
            } else if (response[1] != false && response.length > 0) {
                if (cart_btn) {
                    cart_btn.classList.add('count');
                    cart_btn.classList.remove('none');

                    //Cas ou il n'y a pas encore de produit défini dans data-cart
                    if (!cart_btn.getAttribute('data-cart')) {
                        cart_btn.setAttribute('data-cart', parseInt(this.data.quantity))
                    } else {
                        //On récupère le nombre de produits total dans le panier
                        var initialCount = cart_btn.getAttribute('data-cart');
                        //Et on ajoute à ce total la quantité choisie
                        var finalCount = parseInt(initialCount) + parseInt(this.data.quantity);
                        cart_btn.setAttribute('data-cart', finalCount);
                    }
                }
            }

            if (this.data.configurator == 'true' && response[1] != false && response[4] == 0) {
                var data_conf = {};
                data_conf = this.data;
                data_conf.produit_id = this.data.idprod;
                data_conf.color_id = this.data.idcouleur;
                data_conf.size_id = this.data.idtaille;
                data_conf.conf_color_id = this.data.configurator_color;
                data_conf.conf_color_hex = this.data.configurator_color_hex;
                data_conf.conf_color_name = this.data.configurator_color_name;
                data_conf.conf_text = this.data.configurator_text;

                // Sauvegarde de la configuration du produit
                saveMobileConfigAddBasket(data_conf);
            }

            modbox = 'item_added';

            $(this).trigger('itmAddedToCart');

            // Réinitialisation composant achat express mobile v2
            if ($(this).length > 0 && $(this).find('.productSizeFieldset.actif').length > 0) {
                $(this).find('.productSizeFieldset.actif').removeClass('actif');
            }
        }

        // push data from response into DL
        if (response.length >= 0 && response[5] !== undefined) {
            var dl_objet =response[5];
            if ( typeof is_achat_express !== 'undefined' && is_achat_express) {
                var new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);
            }
            pushIntoDatalayer(dl_objet);
        }

        if (typeof dataLayer_v3 !== 'undefined' ) {
            if (response.length >= 0 && response[6] !== undefined) {
                var dlv3_objet = JSON.parse(response[6]);
                DataLayerTrigger.addToCart(dlv3_objet['items'], dlv3_objet['actionField'], is_achat_express);
            }
        }

    } else {

        $('#' + modbox).find('.modbox_title').html(res.error.msg);
    }

    window.setTimeout(function () {

        var loader = $('.loader');

        if (loader.hasClass('loading')) {

            loader.removeClass('loading');
        } else {

            loader.parent().removeClass('loading');
        }
    }, 400);

    openModalBox.call(document.getElementById(modbox));
}

function openMultiShad(id, msg) {
    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {
        $("#" + id).slideDown(600);
    });

    $("#shade").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
        closeMultiShad(id);
    } );

    if (id == 'popup_cofidis3cb') {
        cofidisScrollbar();
    }

    if (id && id.startsWith('relay_choice')) {
        $('#shade').addClass('relay_lightbox');
        $("#" + id).addClass('active');
    }


    if (id == 'lightboxCustomerAddress') {
        $('#shade').addClass('adressLightbox');
        $("#" + id).addClass('active');
    }

    if (id == 'delivery_home_choice') {
        $('#shade').addClass('delivery_home_choice');
        $("#" + id).addClass('active');
    }

    if ((id === 'delivery_home_choice') ||
        (id === 'lightboxCustomerAddress') ||
        id.startsWith('relay_choice_')||
        id.startsWith('confirmDeleteProductBasket_')) {

        $('body').addClass('noscroll');

        setTimeout(function () {
            $('.sidebar-right').overlayScrollbars({});
        }, 500);
    }

    if (id == "selection-offer") {
        setTimeout(function () {
            var color_list = new Swiper('.color_list', {
                spaceBetween: 15,
                slidesPerView: 'auto',
                simulateTouch: true,
                prevButton: '.prevColour',
                nextButton: '.nextColour',
            });

            var size_list = new Swiper('.size_list', {
                spaceBetween: 15,
                slidesPerView: 'auto',
                simulateTouch: true,
                prevButton: '.prevColour',
                nextButton: '.nextColour',
            });
        }, 500);

         $('#shade').addClass('selectionOffer');
    }
}

function closeMultiShad(id, msg) {

    $('#shade').off('click');
    $("#" + id).removeClass('open');

    if (id && id.startsWith('relay_choice')) {
        $('#shade').removeClass('relay_lightbox');
        $("#" + id).removeClass('active');
    }

    if (id == 'lightboxCustomerAddress') {
        $('#shade').removeClass('adressLightbox');
        $("#" + id).removeClass('active');
    }

    if (id == 'delivery_home_choice') {
        $('#shade').removeClass('delivery_home_choice');
        $("#" + id).removeClass('active');
    }

    if (id == 'confirmDeleteProductBasket') {
        setTimeout(() => {
            btnConfirmSticky();
        }, 2800);
    }

    if (id == "selection-offer") {
         $('#shade').removeClass('selectionOffer');
    }

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {
        if ($('#shade').is(":visible")) {
            $('#shade').fadeTo("slow", 0, function () {
                $('#shade').css('display', 'none');
            });
        }
        $('#general_shade').css('display', 'none');

        $(this).dequeue();
    });

    // Hide shade when remove from basket is confirmed
    if (id.includes('confirmDeleteProductBasket_') && $('#shade').is(":visible")) {
        $('#shade').fadeTo("slow", 0, function () {
            $('#shade').css('display', 'none');
        });
    }

    $('body').removeClass('noscroll');
}

function toggleMenuExtra() {
    close_search();
}

/* Search management */
function show_search() {
    var search_homeV2 = document.getElementById("search_homeV2");
    var top_search = document.getElementById("top_searchV2");
    var site_head = document.getElementById("site_head");
    var shade = document.getElementById("shade");

    // Check if elements exist
    if (!search_homeV2 || !top_search || !site_head || !shade) {
        console.error('One or more elements could not be found');
        return;
    }

    if (top_search.classList.contains("opened")) {
        close_search();
    } else {
        search_homeV2.focus();
        site_head.classList.add("sticky");
        top_search.classList.add("opened");
        shade.classList.add("visible", "forSearch");
        document.body.classList.add("noscroll");
    }
}

function close_search() {
    var top_search = document.getElementById("top_searchV2");
    var scrollTop = $(document).scrollTop();
    var site_head = document.getElementById("site_head");
    var shade = document.getElementById("shade");

    top_search.classList.remove("opened");

    if (scrollTop === 0) {
        site_head.classList.remove("sticky");
    }

    if (shade.classList.contains("forSearch") && !shade.classList.contains("forMenu")) {
        shade.classList.remove("visible","forSearch");
        document.body.classList.remove("noscroll");
    } else {
        shade.classList.remove("forSearch");
    }
}

/* Used only to hide searchbar if value is empty*/
function hide_search() {
    var top_search = document.getElementById("top_searchV2");
    var search_homeV2 = document.getElementById("search_homeV2");
    var shade = document.getElementById("shade");

    if (search_homeV2.value == "" && document.activeElement != search_homeV2) {
        top_search.classList.remove("opened");
        document.body.classList.remove("noscroll");

        if (shade.classList.contains("forSearch")) {
            shade.classList.remove("visible","forSearch");
        }

    } else {
        search_homeV2.value = "";
        search_homeV2.focus();
    }
}

$(document).on('click','#shade.forSearch', function() {
    close_search();
});

// Initialisation des JS pour la recherche V2
function triggerFunctionOnSearchEnd() {
    //Init des swiper dans le cas des visuel multi des produit (MOBILE_MULTI_VISUALS)
    initItemSlider(false);
    close_search();
}

// FP cross sell sliders params
var assoSwiperParams = {
    slidesPerView: 2,
    spaceBetween: 12
};

function getStoreDetail(store) {
    window.location.assign(store);
}

function scrollToDonationBox() {
    if ($('.cart.fr #container_other_information').length) {
        $(".donation_savoir_plus").on('click tap touch', function () {
            // Calculate the scroll position to center the container
            var screenHeight = $(window).height();
            var containerHeight = $("#movedInfo #container_other_information").height();
            var scrollTo = (containerHeight - screenHeight) / 2;

            // Scroll to the calculated position with smooth animation
            $('html, body').animate({
                scrollTop: $("#movedInfo #container_other_information").offset().top + scrollTo
            }, 800);
        });
    }
}

//filter date cf TJ
function reinitFilters(name) {
    var menu = document.querySelector('.drawer.' + name);
    if (menu) {
        var checked_inputs = menu.querySelectorAll('.drawer.'+name+' [type="checkbox"]:checked');
        if (checked_inputs) {
            checked_inputs.forEach(function(el) {
                el.click();
            });
        }
    }
}

function toggleDrawer(name) {
    var menu = document.querySelector('.drawer.' + name);
    if (menu) {
        menu.classList.toggle('open');
    }
}

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site: site
        },
        success: function (data) {
            if (data != '') {

                var force = '';
                if (uri == '/vanessabruno.html') {
                    force = '?force';
                }

                location.href = data + force;

            }
        }
    });
}

/**
 * Check if 'element' is in viewport
 */
function isInViewport(element) {

    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

function initItemSlider(isToggled) {

    if ($('.category .prodItemSlider, .searchItemSlider, .dynasearchwrapper .prodItemSlider').length) {
        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php
        if (isToggled == true) { // Toggle view is triggerred
            setTimeout(function () {
                $('.category .prodItemSlider, .searchItemSlider, .dynasearchwrapper .prodItemSlider').each(function () {
                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll
            $('.category .prodItemSlider').each(function () {
                if (isInViewport($(this)) && !$(this).hasClass('initialized') && $(this).find('.swiper-slide').length > 1) { // Prevent initialized sliders to go back to the first frame during scroll
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazy: swiperLazy,
                        observer: true,
                        observeParents: true,
                        speed: 300,
                        pagination: '.itemSliderPagination',
                    });
                    $(this).addClass('initialized');
                }
            });

            $(".searchItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass('initialized') && $(this).find('.swiper-slide').length > 1) {
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazy: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: '.itemSliderPagination',
                    });
                    $(this).addClass('initialized');
                }
            });

            setTimeout(() => {
                $('.dynasearchwrapper .prodItemSlider').each(function () {
                    if (isInViewport($(this)) && !$(this).hasClass('initialized') && $(this).find('.swiper-slide').length > 1) {
                        new Swiper($(this), {
                            roundLengths: true,
                            slidesPerView: 1,
                            loop: true,
                            preloadImages: false,
                            lazy: swiperLazy,
                            observer: true,
                            observeParents: true,
                            speed: 300,
                            pagination: '.itemSliderPagination',
                        });

                        $(this).addClass('initialized');
                    }
                });
            }, 500);
        }
    }
}

/*/ Vous aimerez Portez avec/*/
var vousAimerez = $('.product_satisfaction #vousAimerez');
var portezAvec = $('.product_satisfaction #portezAvec');
var slider1 = $('#carousel_wrapper .ranges_carousel_wrapper.associations');
var slider2 = $('#carousel_wrapper .ranges_carousel_wrapper.ranges');
vousAimerez.addClass("active");

if (("#carousel_wrapper").length > 0) {
    portezAvec.on("click touch", function () {
        vousAimerez.removeClass("active");
        portezAvec.addClass("active");
        slider1.hide();
        slider2.show();
    });

    vousAimerez.on("click touch", function () {
        portezAvec.removeClass("active");
        vousAimerez.addClass("active");
        slider2.hide();
        slider1.show();
    });
}

var global_id_transporteur = '';

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var numDIVObjet = document.getElementById("popup_numero_retour");

    numDIVObjet.style.display = "block";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = ".2";
    document.getElementById("general_shade").style.position = "fixed";
    document.getElementById("general_shade").style.zIndex = "35";
    document.getElementById("general_shade").style.left = "0";

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

    $("html, body").animate({
        scrollTop: 0
    }, (speed * 2), function () {});
}


function createEtiquetteRetour(idOrder, multiTransp, numRetour = '') {

    var recommand = $(".recommandation").length;
    var has_errors = false;
    $(".return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {
        let data = $('#return_products_form').serialize();
        data += '&idOrder=' + idOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;

        $.ajax({
            url : path_relative_root + 'ajax_create_etiquette.php',
            type : 'post',
            data : data,
            success : function (res) {
                $('#divBtnPrintPdf').hide();
                location.reload();
            }
        });
    } else {
        if (recommand) {
            $(".recommandation p span").css("color", "#a40000");
            $('p[id^="liste_motif_retour"] .select').css(
                "border",
                "2px solid #a40000"
            );
        }
    }
}

// Zooms in item main visual
function zoomImg() {

    var container = $(this).closest('.productVisuals'),
        modbox = $('#zoomBox'),
        shad = $('#al'),
        colors = $('#autres_couleurs img', container),
        visuals = $('#autres_vues img', container),
        col_tgt = $('.col', modbox),
        vis_tgt = $('.vis', modbox);

    col_tgt.html('');
    vis_tgt.html('');

    if ($('ul#product_pictures_slider li').length > 1) {
        zoomUpdateMain($('.swiper-slide-active img').attr('src').replace('/prodfp/', '/large/'));
    } else {
        zoomUpdateMain($('.swipe_slider_visu img', container).attr('src').replace('/prodfp/', '/large/'));
    }

    if (colors.length === 0) {
        col_tgt.parent().hide();
    } else {
        colors.each(zoomAddCol);
    }

    if (visuals.length === 0) {
        vis_tgt.parent().hide();
    } else {
        visuals.each(zoomAddVis);
    }

    shad.unbind('click').one('click', closeModalBox.bind(modbox));

    $(modbox).add(shad).show();
}

$(document).on('click', '.accordion_box', function () {
    $(this).toggleClass('actif');
});

/* ACCORDION */
function accordion(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#accordion_content_" + i).css('display') == 'block') {
            $("#accordion_content_" + i).slideUp(250);
            $("#accordion_label_" + i).removeClass('actif');
        }
    }

    if ($("#accordion_content_" + id).css('display') != 'block') {
        $("#accordion_content_" + id).slideDown(250);
        $("#accordion_content_" + id).addClass('actif');
        $("#accordion_label_" + id).addClass('actif');
    } else {
        $("#accordion_content_" + id).slideUp(250);
        $("#accordion_content_" + id).removeClass('actif');
        $("#accordion_label_" + id).removeClass('actif');
    }
}

// FAQ
function faqinit(array_questions) {
    $(document).on('change keyup focus', '#faq_search', function () {
        showTabFaq();
        var search = $(this).val();
        if (search && search.length > 3) {
            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {
                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());
                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }
            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }

        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1)
    });
}

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({
        scrollTop: $(".faq_question_" + question_id + "").offset().top - 100
    }, 1000);
}

$(function () {
    /**
     * Change table tarif transport position under
     * Article IV - livraison
     * Page CGV
     *
     */
    moveTablePos();

    /**
     * Page CGV
     */
    addSpaceBetweenLogo();

    // on bouge l'ariane sur les pages textes
    moveBreadcrumbs();

    // COVER MODULE SWIPER
    if ($(".sliderMainHome").length) {
        new Swiper(".sliderMainHome", {
            roundLengths: true,
            preloadImages: true,
            lazyLoading: true,
            slidesPerView: 1,
            autoplay: 5000,
            autoplayDisableOnInteraction: false,
            loop: true,
            observeParents: true,
            loopedSlides: 2,
            observer: true,
            pagination: '.swiper-pagination',
        });
    }

    $(".row .content").click(function () {

        var test = $(this).attr("class");

        if ($(this).hasClass('open')) {
            $(this).removeClass('open');
            $(this).find('.content_inside').removeClass('show');
        } else {
            $(this).addClass('open');
            $(this).find('.content_inside').addClass('show');
        }

    });

    if ($("body").hasClass("category") && query_stock_in_cache) {
        getGlobalStock();
    }

    /**
     * MON COMPTE SWIPER
     */
    if ($('#menu_account_slider_mob .swiper-slide').length > 1) {
        var menuInitialSlide = $('#menu_account_slider_mob .swiper-slide.activeMenu').index();
        var centerSlide = "";
        if ($('#clientForm').length > 0) {
            centerSlide = false;
        } else {
            centerSlide = true;
        }

        new Swiper('#menu_account_slider_mob.swiper-container', {
            slidesPerView: 'auto',
            spaceBetween: 24,
            initialSlide: menuInitialSlide,
            on: {
                init: function () {
                    $('.menu_account_slider_mob').fadeTo("slow", 1);
                },
            },
        });
    }

    var siteHeader = document.getElementById("site_head");
    var productPage = document.querySelector('body.product_page');

    function onWinScroll() {
        var scrollTop = $(document).scrollTop();

        if (siteHeader.classList.contains("sticky")) {
            if (scrollTop === 0) {
                siteHeader.classList.remove("sticky");

                if (productPage) {
                    productPage.classList.remove('header-is-sticky');
                }
            }
        } else {
            if (scrollTop !== 0) {
                siteHeader.classList.add("sticky");

                if (productPage) {
                    productPage.classList.add('header-is-sticky');
                }
            }
        }
    }
    $(window).on("scroll", onWinScroll);

    onWinScroll();

    // Footer accordion
    $('.block_links_footer .title_wrap').each(function () {
        $(this).click(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('.footer_nav').removeClass('active').slideUp(300);
            } else {
                $('.block_links_footer .title_wrap').removeClass('active');
                $('.block_links_footer .footer_nav').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).parent().find('.footer_nav').addClass('active').slideDown(300);
            }
        });
    });

    if ($(".satellite_store_details").length) {

        $("#breadcrumbs").insertAfter($("h2.title"));

        var btnGoToStore = document.getElementById("go_to_store");
        btnGoToStore.classList.remove("secondary");

        $(".button.maps").insertAfter($("#store_map"));
    }

    if ($(".store_locator.mobile").length) {
        $("#breadcrumbs").insertAfter($(".first_span"));
    }

    // GIFTCARD PAGE
    if ($('.pageGiftCard').length && $('#breadcrumbs').length) {
        $("#breadcrumbs").detach().appendTo(".pageGiftCard #giftCardIntro");
    }

    if ($("body.script_renderpwdforget").length) {
        const placeholderRenderPassword = document.querySelector("#clientPwd");
        placeholderRenderPassword.placeholder = Translator.translate('login_mdp');
    }

    if ($('body').hasClass('customer') && $('body').hasClass('body_login') && $('body').hasClass('pwdforget')) {
        const $input = $('#clientPwd');
        if ($input.length) {
            $input.attr('placeholder', $input.attr('placeholder').replace(/\* \*$/, ' *'));
            $input.css('opacity','1');
        }
    }

    // Function pour gérer le bouton Lire la suite sur la page rayon
    $('.btn_see_text').on('click', function () {

        if ($(this).parents('.textSeo_bloc_description').hasClass('see_more')) {
            $(this).parents('.textSeo_bloc_description').removeClass('see_more').addClass('see_less');
        } else {
            $(this).parents('.textSeo_bloc_description').removeClass('see_less').addClass('see_more');
        }
    });

    /**
    LEFT MENU DROPDOWN USER CONNECTED
    */
    if ($('#left_menu .bloc_lnk.user.connected.dropdown').length > 0) {

        $(document).on('click tap touch', '.bloc_lnk.user.connected.dropdown', function (e) {
            if ($(this).hasClass('active')) {

                $(this).removeClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideUp(300);
            } else {

                $(this).addClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideDown(300);
            }
        });
    }

    //Swiper Rayon SEO
    if ($('.textSeo .swiper-slide').length > 1) {
        var SeoSwipers = new Swiper(".wrapper_seo_liens.swiper-container", {
            slidesPerView: 2,
        });
    }

    /**
     * Add special class when cart upselling Cofidis is visible
     */
    if ($('.encart_3xcb_panier').length) {

        $('.order_recap').addClass('upselling_cofidis');
    }

    //Swiper Rayon SEO
    if ($('body.category .wrapper_push_rayons  .swiper-slide').length > 1) {
        var PushSwipers = new Swiper(".wrapper_push_rayons.swiper-container", {
            slidesPerView: 2.5,
        });
    }

    /**
     * Switch bredcrumb position on store locator
     */
    if ($('.store_locator').length) {

        $('#breadcrumbs').insertAfter('.titre_stores span:first-child');
    }

    // Page Tunnel
    setTimeout(function () {
        if ($('body').hasClass('cart')) {
            $('#main_menu_btn').on('click', function () {
                if ($('.cart_item_actions').hasClass('actif')) {
                    $('.cart_item_actions').removeClass('actif');
                }
            });

            // On execute les codes après l'ajax
            $(document).ajaxComplete(function () {
                if ($('.encart_3xcb_panier').length) {
                    $('.order_recap').addClass('upselling_cofidis');
                }
            });
        }
    }, 500);

    // Calibrage body
    if (!$('.bandeauMenuAffix ').length && !$('.bandeauMenuAffix ').is(":visible")) {
        $('body').addClass('calibrate');
    } else {
        $('body').removeClass('calibrate');
    }

    if ($('#isGift').length) {
        $('.order_gift_form').show();
    } else {
        $('.order_gift_form').hide();
    }

    if ($('#isDonation').length) {
        $('.order_donation_form').show();
    } else {
        $('.order_donation_form').hide();
    }

    $('#is_order_gift').on('change', function () {
        if ($('#isGift').length) {
            $('#isGift').click();
        }
    });

    $('#is_order_donation').on('change', function () {
        if ($('#isDonation').length) {
            $('#isDonation').click();
        }
    });

    // Init module cms swiper tg cms
    if ($('.tgCms').length) {

        var journalModuleMobileSwiper = new Swiper(".tgCms.swiper", {
            slidesPerView: 1.8,
            loop: false,
            spaceBetween: 12,
            scrollbar: '.cms-tg-swiper-scrollbar',
            scrollbarDraggable: true,
            scrollbarHide: false,
        });
    }

    // Init module cms swiper tg cms
    if ($('.three-images.full').length) {
        setTimeout(function () {
            var journalThreeImagesMobileSwiper = new Swiper(".three-images.full .cms-page-columns-container", {
                slidesPerView: 1,
                loop: false,
            });
        }, 500);
    }

    // Init module cms swiper 3 images
    if ($('.three-images:not(.full)').length) {
        setTimeout(function () {
            var journalFourImagesMobileSwiper = new Swiper(".three-images:not(.full) .cms-page-columns-container", {
                slidesPerView: 2,
                loop: false,
                spaceBetween: 12,
                centeredSlides: true,
                initialSlide: 1,
            });
        }, 500);
    }

    // Init module cms swiper 4 images
    if ($('.four-images:not(.instagram)').length) {
        setTimeout(function () {
            var journalFourImagesMobileSwiper = new Swiper(".four-images:not(.instagram) .cms-page-columns-container", {
                slidesPerView: 1.9,
                loop: false,
                spaceBetween: 12,
            });
        }, 500);
    }

    // Init module cms swiper 4 images + 4 cta
    if ($('.four-images-four-cta').length) {
        setTimeout(function () {
            var journalFourImagesCtaMobileSwiper = new Swiper(".four-images-four-cta .cms-page-columns-container", {
                slidesPerView: 1.28,
                loop: false,
                spaceBetween: 12,
            });
        }, 500);
    }

    // Init module cms swiper 4 images + 1 cta
    if ($('.four-images-cta').length) {
        setTimeout(function () {
            var journalFourImagesFourCtaMobileSwiper = new Swiper(".four-images-cta .cms-page-column", {
                slidesPerView: 1.88,
                loop: false,
                spaceBetween: 12,
            });
        }, 500);
    }

    // Init module cms swiper instagram
    if ($('.cms-page-module.four-images.instagram').length) {
        setTimeout(function () {
            var journalFourImagesCtaMobileSwiper = new Swiper(".four-images.instagram .cms-page-column", {
                slidesPerView: 1.70,
                loop: false,
            });
        }, 500);
    }

    // Init module cms swiper article list
    if ($('#articles_list_swiper').length) {
        setTimeout(function () {
            var journalFourImagesCtaMobileSwiper = new Swiper("#articles_list_swiper", {
                slidesPerView: 1,
                loop: false,
                prevButton: '.articles-list-swiper-button-prev',
                nextButton: '.articles-list-swiper-button-next',
            });
        }, 500);
    }

    // CMS PAGES FILTER DATE
    if ($('.cms_category .trigger_drawer').length) {

        $('.trigger_holder').on('click', function (e) {
            $('.cms_subcategory_menu .drawer').removeClass('open');

            if ($(this).find('.trigger_drawer').hasClass('menu_list')) {

                toggleDrawer('cms_menu_list');

            } else if ($(this).find('.trigger_drawer').hasClass('date_list')) {
                toggleDrawer('dates_holder');

            }
        });
    }

    setTimeout(function () {
        // w-newsletter-form insertBefore
        if ($(".w-newsletter-form").length) {
            $(".legal_notices").insertBefore(".w-newsletter-form .w-submit");
        }

        // w-login-form insertAfter
        if ($(".w-newlogin-form").length) {
            $("p.legal_notices").insertAfter(".login_form_wrapper .title .subtitle.cache").show();
        }
    }, 100);

    /**
     * Product cross sell sliders
     */
    if ($('.ranges_slider').length) {

        var ranges_slider = new Swiper('.ranges_slider', {

            slidesPerView: 2,
            spaceBetween: 12
        });
    }

    if ($('.assoc_slider').length) {

        var assoc_slider = new Swiper('.assoc_slider', {

            slidesPerView: 2,
            spaceBetween: 12
        });
    }

    if ($('.assos_theme').length) {

        var assoc_slider = new Swiper('.assos_theme', {

            slidesPerView: 2,
            spaceBetween: 12
        });
    }

    if ($('.assos_product').length) {

        var assoc_slider = new Swiper('.assos_product', {

            slidesPerView: 2,
            spaceBetween: 12
        });
    }

    if ($('#TGSwiper .swiper-slide').length > 1) {
        setTimeout(function () {
            var TGSwipers = new Swiper("#TGSwiper.swiper-container", {
                slidesPerView: 3,
                spaceBetween: 9,
            });
        }, 150);
    }

    let counter = 0;
    const maxExecutions = 6;

    const intervalId = setInterval(function() {
        if ($("#sliderBando .swiper-slide").length > 1) {
            var bandoHeadermobile = new Swiper("#sliderBando", {
                slidesPerView: 1,
                loop: true,
                speed: 1500,
                simulateTouch: true,
                autoplay: 5000,
                effect: "slide",
                direction: "horizontal",
                autoplayDisableOnInteraction: false,
                paginationClickable: true,
                preloadImages: true,
                lazyLoading: true,
            });
        }

        counter++;
        if (counter >= maxExecutions) {
            clearInterval(intervalId);
        }
    }, 1000);

    $('div.toggleNumObjects span').click(function() {

        if ($(this).attr('id') == 'oneProduct'){
            $('div.item_container').addClass('full');
            $('.list_item').removeClass('viewPerTwo');
            createCookie('nb_produits', 1, 1);
            switchVisu();
        } else {
            $('div.item_container').removeClass('full');
            $('.list_item').addClass('viewPerTwo');
            createCookie('nb_produits', 2, 1);
            switchVisu();
        }

        $('div.toggleNumObjects span').removeClass('actif');
        $(this).addClass('actif');
    });

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            }, 500);
        }
    });

    setTimeout(function () {
        if ($('body').hasClass('body_login') &&
            $('body').hasClass('cart') &&
            $('#subtitleLogin').length) {

            $('<div class="titleWrapper"></div>').append($('.title #subtitleLogin, .title #subtitlePassword, .title .subtitle.legal_notices')).appendTo('.login_bloc + .login_bloc');
        }

        if (($('body').hasClass('cart') || $('body').hasClass('checkout')) && !$('body').hasClass('step_1')) {
            var montantTxtClone = $('#montant_txt').clone();
            var nbProductsClone = $('.nb_products').clone();
            var recapCartTitleContent = $('<span class="title_and_nb_products">').append($('.recap_cart_title').contents()).append(nbProductsClone);

            $('.recap_cart_title')
                .empty()
                .append(recapCartTitleContent)
                .append(montantTxtClone)
                .prependTo('#tunnel_right_col');

            $('.recap_cart_title').on('click', function() {
                $('#cart_total').slideToggle();
                $('.recap_cart_title, #cart_total').toggleClass('active');
            });

            if ($('.wrapper_payment').length) {
                $('.wrapper_payment').insertAfter('#cart_total');
            }
        }

        // PAGE LIVRAISON DELAY DISPLAY
        if ($('.cart.step_2').length || $('body.checkout').length) {
            setTimeout(() => {
                 $('#appContainer').addClass('appear');
            }, 50);
        }

        if ($('.deconnection_tunnel').length && $('#cart_total').length) {
            $('.deconnection_tunnel').prependTo('#main_cart_wrapper');
        }

        if ($('#around_me_map').length) {
            $('#around_me_map').appendTo('.thanksRecap .thanksRight');
        }

        if ($('.thanksRightWrapper .num_order').length) {
            var orderMoveToTitle = $('.thanksRightWrapper .num_order');

            $('.thanksWrapper h1').append(orderMoveToTitle);
        }

        if ($('body').hasClass('cart step_2')) {
            if ($('.left_side.elem_tunnel').find('#cart_total2').length === 0) {
                var $cartTotal = $('#cart_total');
                var $clonedCartTotal = $cartTotal.clone().attr('id', 'cart_total2');
                var $btnCmdValidClone = $clonedCartTotal.find('#btn_cmd_valid').attr('id', 'btn_cmd_valid_clone');

                $btnCmdValidClone.wrap('<div class="fixed_button_wrapper"></div>');
                $clonedCartTotal.find('.total_produit_dropdown, .upselling_alma, .mot_personnalise_form').remove();
                $clonedCartTotal.appendTo('.left_side.elem_tunnel');
            }
        }

        $(window).on('scroll', function() {
            var $cartTotal2 = $('#cart_total2');
            var $fixedButtonWrapper = $('.fixed_button_wrapper');

            // Check if #cart_total2 exists
            if ($cartTotal2.length) {
                if (isInViewport($cartTotal2)) {
                    $fixedButtonWrapper.removeClass('fixed');
                } else {
                    $fixedButtonWrapper.addClass('fixed');
                }
            } else {
                // Optionally handle the case where #cart_total2 does not exist
                $fixedButtonWrapper.removeClass('fixed');
            }
        });

        $(document).on('click', '.kp .relayLink span', function() {
            $(this).parents('.delivery_lightbox').removeClass('active');
            $('#shade').fadeOut();
        });
    }, 1000);

    $('body').on('click', '.payment_mode_btn span', function() {
        if ($('#bloc_erreur_order').length) {
            $('#bloc_erreur_order').hide();
        }
    });

    cart_switch_view();
    cartSwipeGondole();
    cartExistInWishlist();

    if ($('body').hasClass('product_page')) {
        // On click display VR video
        $(document).on('click tap touch', '#vrButton', function() {
            // Display VR video
            $('#metavrsVideo').css('display', 'block');
            // Prevent body scroll
            $('body').css('overflow', 'hidden');
        });

        // On click hide VR video
        $(document).on('click tap touch', '#metavrsVideo .close_vr', function() {
            // Hide VR video
            $('#metavrsVideo').css('display', 'none');
            // Allow body scroll again
            $('body').css('overflow', 'auto');
        });
    }

    setTimeout(function () {
        // Checking GlobalE active
        if (typeof GEM_Services != "undefined" && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != "undefined") {
            if (GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE == true) {
                $('body').addClass('isOperatedByGlobalE');
            } else {
                $('body').removeClass('isOperatedByGlobalE');
            }
        }
    }, 1000);
    scrollToDonationBox();

    // change text submit new address tunnel
    if($('body.cart #new_address').length) {
        $('body.cart #new_address').find('.w-form-line.w-submit .w-submit-button span').text(Translator.translate('finalise_order'));
    }

    btnConfirmSticky();

    /* Cover module */
    if ($('.home-module.four-images').length) {
        new Swiper('.four-images.swiper-container', {
            loop: true,
            slidesPerView: 1.5,
            speed: 2000
        });
    }

     // Site Map PAGE
    if ($('.footer_sitemap').length && $('#breadcrumbs').length) {
        $("#breadcrumbs").detach().insertAfter("#wrapper_sitemap h1");
    }

    /**
    * Load global .item_container slider initialization on window scroll
    */
    setTimeout(function () {
        initItemSlider(false);
    },100);

    window.addEventListener("scroll", function () {
        setTimeout(function () {
            initItemSlider(false);
        },100);
    });

    /*/ Add Total in whislist /*/
    if ($('#nrArticlesSelected').length) {
        $('<span class="txt_total">Total : </span>').insertBefore('#nrArticlesSelected .wish_price #total_price');
    }

    /*/ Lookbook /*/
    if ($('body.lookbook').length) {
        $('body.lookbook .total_look.clearfix').insertAfter('body.lookbook #content_product');
    }

    /*/ newsletter_check /*/
    if ($("label[for='newsletter_check']").length) {
        $("label[for='newsletter_check']").parent().addClass('newsletter_check_ctn');
    }

    /* delete card bloc */
    if ($("#menu_account_card").length) {
        $("#menu_account_card").remove();
    }

    // Voir tout par défaut
    if ($('.page_rayon .see_all').length) {
        $(".see_all").click();
    }

    if ($('body.homepage').length) {
        $(".homepage .home-module-cta a").each(function() {

            if (!$(this).text().trim().length) {
                $(this).css('border', 'transparent').hide();
            } else {
                $(this).css('opacity', '1');
            }
        });

        $(".homepage .home-module-cta").each(function() {

            if (!$(this).text().trim().length) {
                $(this).find('.tiret_cta').css('background', 'transparent').hide();
            } else {
                $(this).find('.tiret_cta').css('opacity', '1');
            }
        });
    }

    // Init home module TG swiper
    if ($('.home-module.tg-module').length) {

        var homeTgSwiper = new Swiper(".tg-module .main-swiper", {
            slidesPerView: 1.8,
            loop: false,
            freeMode: true,
            spaceBetween: 12,
            scrollbar: '.tg_pagination',
            scrollbarDraggable: true,
            scrollbarHide: false,
            slidesOffsetBefore: 20,
            slidesOffsetAfter: 20,
        });
    }

    // TUNNEL RETRAIT MAG
    if ($('.cart.step_2 .bloc_livraison .title_horraire').length) {
        $('.cart.step_2 .bloc_livraison .title_horraire').on('click touch', function () {
            if (!$(this).hasClass('deployed')) {
                if ($(this).parent().find('.horaire').length > 0) {
                    $(this).addClass('deployed');
                    $(this).parent().find('.horaire').slideDown(500);
                }
            } else {
                if ($(this).parent().find('.horaire').length > 0) {
                    $(this).removeClass('deployed');
                    $(this).parent().find('.horaire').slideUp(500);
                }
            }
        });
    }

    if ($('#paiement_3Times_cofidis').length && !$('#paiement_3Times_cofidis').hasClass('opened')) {
        $('#paiement_3Times_cofidis').addClass('wrapper_bloc_banque');
        $('#paiement_3Times_cofidis .bank_subtitle').on('click touch', function(){
            $(this).next('.accordion_title').click();
        });
    }

    if ($('body').hasClass('checkout')) {
        if ($('.wrapper_bloc_banque.credit_card').length) {
            $('.credit_card').addClass('actif');
        }

        $('.wrapper_bloc_banque').on('click touch', function(){
            if (!$(this).hasClass('actif')) {
                $(this).addClass('actif');
                $('.wrapper_bloc_banque').not(this).removeClass('actif');
            } else if ($(this).hasClass('accordion_item') && $(this).hasClass('opened')) {
                $(this).next('.accordion_title').click();
                $(this).removeClass('actif');
            }

            if ($('.wrapper_bloc_banque').not(this).hasClass('opened')) {
                $('.wrapper_bloc_banque.opened .accordion_contents').slideToggle();
                $('.wrapper_bloc_banque.opened').removeClass('opened');
            }
        });
    }

    // toggle page CSM
    if ($('.dropdown_cms').length) {
        $(".dropdown_cms").click(function () {
            $(this).next('ul.menu_cms').slideToggle();
        });
    }

    // On reload, Check if page was previously set to view by 1 and fix classes accordingly
    if ($('body.category').length && !$('body.inSearch').length && readCookie('nb_produits') == 1){
        $('#wrapper_visus_produit .list_item').removeClass('viewPerTwo');
        $('#wrapper_visus_produit .list_item div.item_container').addClass('full');
    }

    // ADD EYE TO SHOW PASSWORD
    function createToggleButton(fieldId, buttonId) {
        const button = $(`<button type="button" id="${buttonId}" class="eye-btn"></button>`);
        button.insertAfter($(`#${fieldId}`));

        button.on('click', function () {
            const field = $(`#${fieldId}`);
            const type = field.attr('type') === 'password' ? 'text' : 'password';
            field.attr('type', type);

            const activeClass = type === 'password' ? '' : 'active';
            $(this).toggleClass('active', type === 'text');
        });
    }

    if ($('body').hasClass('pwdforget')) {
        createToggleButton('clientPwd', 'togglePwd');
        createToggleButton('clientPwdv', 'togglePwdv');
    }

    // MOVE INPUT AND EYE BUTTON INSIDE A DIV
    $('body.pwdforget #clientForm .form_item').each(function() {
        $(this).find('.inputFormSite, .eye-btn').wrapAll('<div class="input-wrapper"></div>');
    });

    /****************************************************
        VERIFICATION PASSWORD START -> PAGE MDP OUBLIÉ
    *****************************************************/

    if ($('body').hasClass('body_login') && $('body').hasClass('pwdforget')) {
        document.getElementById('clientPwd').addEventListener('input', validatePassword);
        document.getElementById('clientPwdv').addEventListener('input', validatePassword);
    }

    function validatePassword() {
        if ($('body').hasClass('body_login') && $('body').hasClass('pwdforget')) {
            const password = document.getElementById('clientPwd').value;
            const confirmPassword = document.getElementById('clientPwdv').value;
            const passwordError = document.getElementById('clientPwdErr');
            const confirmPasswordError = document.getElementById('clientPwdvErr');
            const submitButton = document.getElementById('btn_pwd');
            const passwordField = document.getElementById('clientPwd');
            const confirmPasswordField = document.getElementById('clientPwdv');

            // Réinitialise les messages d'erreur et les classes de validation
            passwordError.style.display = 'none';
            confirmPasswordError.style.display = 'none';
            passwordField.classList.remove('invalid-input');
            confirmPasswordField.classList.remove('invalid-input');
            passwordError.classList.remove('error-text');
            confirmPasswordError.classList.remove('error-text');

            // Regex pour vérifier les caractères alphanumériques (y compris les caractères spéciaux) sans restriction de position
            const alphanumericRegex = /^[a-zA-Z0-9]+$/;
            // Regex pour vérifier si le premier caractère est un caractère spécial
            const specialCharFirstCharRegex = /^[^a-zA-Z0-9]/;

            // Désactive le bouton de soumission
            submitButton.disabled = true;

            // Si le mot de passe commence par un caractère spécial
            if (specialCharFirstCharRegex.test(password)) {
                passwordError.textContent = Translator.translate('pwd_first_char_notspecial');
                passwordError.style.display = 'block';
                passwordField.classList.add('invalid-input');
                passwordError.classList.add('error-text');
                return;
            }

            // Si le mot de passe n'est pas valide
            if (!alphanumericRegex.test(password)) {
                passwordError.textContent = Translator.translate('login_erreur_3');
                passwordError.style.display = 'block';
                passwordField.classList.add('invalid-input');
                passwordError.classList.add('error-text');
                return;
            }

            // Vérifie si les mots de passe correspondent uniquement quand #clientPwdv a changé
            if (confirmPassword !== '' && password !== confirmPassword) {
                confirmPasswordError.textContent = Translator.translate('password_mismatch');
                confirmPasswordError.style.display = 'block';
                confirmPasswordField.classList.add('invalid-input');
                confirmPasswordError.classList.add('error-text');
                return;
            }

            // Si tout est valide, réactive le bouton de soumission
            submitButton.disabled = false;
        }
    }

    if ($("#btn_pwd").length) {
        document.getElementById('btn_pwd').addEventListener('click', function (event) {
            event.preventDefault(); // Empêche le formulaire d'être soumis

            const password = document.getElementById('clientPwd').value;
            const confirmPassword = document.getElementById('clientPwdv').value;

            // Vérifie à nouveau avant de soumettre
            if (password && confirmPassword && password === confirmPassword) {
                document.getElementById('clientForm').submit(); // Soumet le formulaire
            }
        });
    }

    /****************************************************
        VERIFICATION PASSWORD END -> PAGE MDP OUBLIÉ
    *****************************************************/

    $(window).on("removeBasketItemCustom", function (data) {
        // Hide shade once product is removed from basket
        if ($('#shade').length && $('#shade').hasClass('visible')) {
            $('#shade').removeClass('visible');
        }

        // We restore scrolling
        if ($('body').hasClass('noscroll')) {
            $('body').removeClass('noscroll');
        }
    });

    if ($('.cart.step_2').length) {
        setTimeout(function () {
            $('#blocs_address').insertAfter('.delivery_choice.active');
        }, 500);
    }
});

function moveTablePos() {
    var $contentWrapper = $('.order_cgv.mobile .wrapper_content_sat.page_text .content_sat'),
        $table = $contentWrapper.find('.wrapperCgvTable'),
        $lastEl = $contentWrapper.find('#row_bloc_4 #bloc_4 strong').last();

    if ($table.length && $lastEl.length) {
        $table.insertBefore($lastEl);
    }
}

/**
 * Page CGV
 * add space to logo image
 */
function addSpaceBetweenLogo() {
    var bloc3 = $('.order_cgv.mobile .content_sat #row_bloc_3 #bloc_3');
    var img = bloc3.children('img[src$="systempay.jpg"]');

    if (img.length) {
        img.before('<br>');
    }

}

function moveBreadcrumbs() {
    if ($('.footer_legalmentions, .misc_cookiesinfo, .order_cgv, .footer_mesures_sanitaires').length) {
        $(".satellite_breadcrumbs_wrapper").insertAfter(".page_text h1:first-child");
    }
}

function processDisplayProductInList(obj, result) {
    var has_stock = false
    var i, k;
    var colors = Object.keys(result);

    // Parcours des infos de stock et MAJ dans la variable has_stock pour afficher ou non le bloc indisponible
    // Et grisement des tailles sans stock
    for (i = 0; i < colors.length -1; i++) {
        var color_id = colors[i];
        var sizes = Object.keys(result[color_id]);
        for (k = 0; k < sizes.length; k++) {
            var size_id = sizes[k];
            if (result[color_id][size_id]) {
                has_stock = true;
                obj.find('[name="itm_size"]').filter('[value="' + size_id + '"]').removeClass('disabled');
            } else {
                obj.find('[name="itm_size"]').filter('[value="' + size_id + '"]').addClass('disabled');
            }
        }
    }

    if (has_stock) {
        obj.find('.imgWrapper').removeClass('reminder_on');
    } else {
        obj.find('.imgWrapper').addClass('reminder_on');
    }
}

$('#row_bloc_0 .content').addClass('open');
$('#row_bloc_0 .content .content_inside').addClass('show');

/*/ Add class to Humberger menu, Store locator, Account and basket /*/
$('.banner_nav .header_section:first-child').addClass("menuBurger");
$('.banner_nav .header_section:last-child').addClass("sl_acc_bk");

/*/ Add class to Humberger menu, Store locator, Account and basket /*/
$('.banner_nav .header_section:first-child').addClass("menuBurger");
$('.banner_nav .header_section:last-child').addClass("sl_acc_bk");

/*/ Toggle new search bar /*/
function showhide_search() {
    if (getCookie("cookie_header_delivery") != "accepted") {

        if ($("#top_searchV2").length) {
            $('#top_searchV2').slideToggle().toggleClass('activated');
            var topSearch = $('#top_searchV2').hasClass('activated');
        } else {
            $('#top_search').slideToggle().toggleClass('activated');
            var topSearch = $('#top_search').hasClass('activated');
        }

        if (topSearch) {
            $("body").removeClass('search_hidden');
            $(".banner_wrapper").css('zIndex', 1000);
            $(".banner_wrapper").css('overflow', 'unset');
        } else {
            $("body").addClass('search_hidden');
            $(".banner_wrapper").css('zIndex', 30);
        }
    }
};

// Pour savoir si on a un bandeau (header)
if (getCookie("cookie_header_delivery") != "accepted" && $('.bandeauMenuAffix').length > 0) {
    $('body').addClass('promo_on');
}

function resizeAfterHideBanner() {
    $(".banner_wrapper").css('top', 0);
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

function fadeTaille(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#content_quide_" + i).css('display') == 'block') {
            $("#content_quide_" + i).hide();
            $("#intitule_quide_" + i).removeClass('actif');
        }
    }
    if ($("#content_quide_" + id).css('display') != 'block') {

        $("#content_quide_" + id).fadeIn(400);
        $("#content_quide_" + id).addClass('actif');
        $("#intitule_quide_" + id).addClass('actif');
    } else {
        $("#content_quide_" + id).hide();
        $("#content_quide_" + id).removeClass('actif');
        $("#intitule_quide_" + id).removeClass('actif');
    }

}

/* Menu Top Left */
$('li.menu_item.link_page_adv').each(function () {
    var lastLi = $(this).siblings(":last");
    $(this).insertAfter(lastLi);
});
$('li.menu_item.seeAll').each(function () {
    var lastLi = $(this).siblings(":last");
    $(this).insertAfter(lastLi);
});

function displayMenu() {
    var $this = $('.close_menu .switchLang');
    if ($($this).hasClass('actif')) {
        $($this).removeClass('actif');
    } else {
        $($this).addClass('actif');
    };
}

/*Store locator details swiper*/
if ($('#store-loc-swiper').length > 0) {
    var storeLocSwiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        paginationClickable: true
    });
}

$('#bloc_add_alert + .stock_check').insertAfter('#link_book_in_store');


$('.input_container.vector_sprite.next').each(function () {
    $(this).find('select').focus(function () {
        $(this).parent().addClass('rotate');
    });
    $(this).find('select').focusout(function () {
        $(this).parent().removeClass('rotate');
    });
    $(this).find('select').change(function () {
        $(this).parent().removeClass('rotate');
    });
    var check = $(this).find('select option').length < 3;
    if (check) {
        $(this).addClass('hide');
    }
});

$(".cms-page-module.text-image-3 .cms-page-columns-container .cms-page-column").addClass('swiper-container');
$(".cms-page-module.text-image-3 .cms-page-columns-container .cms-page-module-container-img").addClass('swiper-wrapper');
$(".cms-page-module.text-image-3 .cms-page-columns-container .cms-page-column .cms-page-module-img").addClass('swiper-slide');

// Initialisation du slider atelier si présent
if ($('.cms-page-module.text-image-3 .cms-page-columns-container .swiper-container').length) {
    new Swiper('.cms-page-module.text-image-3 .cms-page-columns-container .swiper-container', {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 8,
    });
}

if ($('.cms-page-module.slider-1 .cms-page-columns-container .swiper-container').length) {
    new Swiper('.cms-page-module.slider-1 .cms-page-columns-container .swiper-container', {
        loop: false,
        slidesPerView: 2,
        spaceBetween: 18,
        pagination: '.swiper-pagination',
        paginationClickable: true
    });
}

if ($('.cms-page-module.slider-2 .cms-page-columns-container .swiper-container').length) {
    new Swiper('.cms-page-module.slider-2 .cms-page-columns-container .swiper-container', {
        loop: false,
        slidesPerView: 1,
        pagination: '.swiper-pagination',
        paginationClickable: true

    });
}

/* AJOUTER CLASS pour le swiper sur l'atelier personnalisation 2ème module */
if ($('.cms-page-module.image-text-1').length) {
    $(".cms-page-module.image-text-1 .cms-page-columns-container").addClass('swiper-container');
    $(".cms-page-module.image-text-1 .cms-page-columns-container .cms-page-column").addClass('swiper-wrapper');
    $(".cms-page-module.image-text-1 .cms-page-columns-container .cms-page-column .cms-page-module-img").addClass('swiper-slide');

    $('<div class="swiper-pagination"></div>').insertAfter('.cms-page-module.image-text-1 .cms-page-columns-container .cms-page-column.swiper-wrapper');
}

if ($('.cms-page-module.image-text-1 .cms-page-columns-container.swiper-container').length) {
    new Swiper('.cms-page-module.image-text-1 .cms-page-columns-container.swiper-container', {
        slidesPerView: 2,
        pagination: '.swiper-pagination',
        paginationClickable: true,
        direction: 'horizontal',
        loop: false,
        slidesPerView: 2,
        spaceBetween: 4,
    });
}

if (getCookie("cookie_header_delivery") != "accepted" && $('.bandeauMenuAffix').length > 0) {

    $(window).on("orientationchange",function(){
      if (window.orientation == 0) {
        $("header.banner_wrapper").css('top', '4.7rem');
    } else {
        $("header.banner_wrapper").css('top', '2.4rem');
    }
    });
}

function unbind_bin_sticky() {
    $('.stick_to_bottom').each(function () {
        $(this).unbind();
    });
    $('.stick_to_bottom').each(function () {
        $(this).stickyBtn();
    });
}

$(document).scroll(function () {
    if ($(this).scrollTop() > 250 || $('body.product_page #site_head #left_menu').hasClass('open')) {
        $('body.product_page .bandeauMenuAffix, body.product_page #site_head').css({
            'opacity': '1',
            'display': 'block'
        });
        $('body.product_page').css('padding-top', '17.6vmin');
    } else {
        $('body.product_page .bandeauMenuAffix, body.product_page #site_head').css({
            'opacity': '0',
            'display': 'none'
        });
        $('body.product_page').css('padding-top', '0');
    }

    fix_total_panier();
});

function fix_total_panier() {
    setTimeout(function () {
        // Check if the required elements exist first
        if ($(".order_total").length > 0 && $("#formPanier").length > 0) {
            var $orderTotal = $(".order_total");
            var $formPanier = $("#formPanier");
            var screensize = $(window).height();
            var $cartAmount = $(".order_total.cart_amount");
            var cartAmountOffset = $cartAmount.length > 0 ? $cartAmount.offset() : null;
            var cart_amount = cartAmountOffset ? cartAmountOffset.top + $cartAmount.outerHeight(true) - $(document).scrollTop() : 0;
            var formPanierOffset = $formPanier.offset();
            var formPanier = formPanierOffset ? formPanierOffset.top - $(document).scrollTop() : 0;
            var formPanier_main = formPanierOffset ? formPanierOffset.top + $formPanier.outerHeight(true) - $(document).scrollTop() : 0;

            // Add additional height if these elements exist
            if ($(".order_total.shipping_rate").length > 0) {
                cart_amount += $(".order_total.shipping_rate").outerHeight(true);
            }

            if ($(".order_total.total_realised_economy").length > 0) {
                cart_amount += $(".order_total.total_realised_economy").outerHeight(true);
            } else {
                cart_amount += cartAmountOffset ? cartAmountOffset.top : 0;
            }

            // Positioning logic
            if ($formPanier.css('position') === 'fixed' && cart_amount >= formPanier) {
                $('.order_total.subtotal').css({
                    position: 'fixed',
                    zIndex: 10
                });
                $('.order_total.total_realised_economy').css({
                    opacity: 0
                });
            } else {
                $('.order_total.subtotal').css({
                    position: 'static',
                    zIndex: 0
                });
                $('.order_total.total_realised_economy').css({
                    opacity: 1
                });
            }
        }
    }, 50);
}

// Lookbook

function loadModboxProduitsAssocies(look_id) {
    var $element = $('#lightbox_achat_express_look');
    $element.find('.main_look_swiper').hide();
    $element.find('.loader_lookbook').addClass('loading');

    // Achat express v2
    function ajax_achat_express_look(look_id) {

        $.ajax({
            type: 'get',
            url: path_relative_root + 'ajax_achat_express_look.php?look_id=' + look_id,
            success: function (response) {
                var $lighbox = $('#lightbox_achat_express_look');

                $('#content_achat_express_look').html(response);

                // On initialise le swiper même lorsqu'il y a un seul produit car sinon on a une différence d'affichage sur la popup
                // De plus on met un petit timeout pour permettre le chargement des produits juste avant le refresh du slider pour éviter des problèmes d'affichage également
                setTimeout(function () {
                    if ($('#look_main_swiper .swiper-slide').length > 1) {
                        $(this).parent().parent().addClass('ajaxreload');
                        var look_main_swiper = new Swiper("#look_main_swiper", {
                            centeredSlides: true,
                            slidesPerView: 1.5,
                            spaceBetween: 15,
                        });
                    }
                }, 300);

                $lighbox.find('.loader_lookbook').removeClass('loading');
                $lighbox.find('.main_look_swiper').show();

                $('body.category .item_container').each(function () {
                    if ($(this).prev().hasClass('full') || $(this).next().hasClass('full')) {
                        $(this).addClass('full');
                    }
                });
            }
        });
    }

    ajax_achat_express_look(look_id);

    $element.addClass('open');

    $element.find('.close_btn').on('click', function () {
        $('body').css('position', 'initial');
        $element.removeClass('open');
    });
}

$(document).scroll(function () {
    if ($(this).scrollTop() > 900) {
        $('#floating_add_basket').removeClass('scrollUp');
        $('#floating_add_basket').addClass('scrollDown');
    } else {
        $('#floating_add_basket').removeClass('scrollDown');
        $('#floating_add_basket').addClass('scrollUp');
    }

    if ($(document).scrollTop() > 500) {
        $('#btt_button').css('display', 'block');
    }
    if ($(document).scrollTop() < 500) {
        $('#btt_button').css('display', 'none');
    }
});

function executeScroll(scrollHeight) {
    $(document).scroll(function () {
        if ($(this).scrollTop() > scrollHeight) {
            $('#floating_add_basket').removeClass('scrollUp');
            $('#floating_add_basket').addClass('scrollDown');
        } else {
            $('#floating_add_basket').removeClass('scrollDown');
            $('#floating_add_basket').addClass('scrollUp');
        }
    });
}

if ($(window).width() == 320) {
    executeScroll(400);
};
if ($(window).width() == 375) {
    executeScroll(450);
};
if ($(window).width() == 414) {
    executeScroll(680);
};
if ($(window).width() == 568) {
    executeScroll(680);
};
if ($(window).width() == 667) {
    executeScroll(780);
};
if ($(window).width() == 736) {
    executeScroll(950);
};
if ($(window).width() == 812) {
    executeScroll(1250);
};

$('.item_container:not(.full) .button.cancel + .button.valid span').each(function () {
    var text = $(this).text();
    text = text.replace("Ajouter au panier", "Ajouter");
    $(this).text(text);
});

/* affichelostpassword */
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');
    var subtitleLegalNotices = $('.subtitle.legal_notices');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    if (signIn.is(":visible")) {
        if (subLogin) {
            subLogin.animate({
                opacity: 0
            }, 600, function () {
                subLogin.css('display', 'none');
                subtitleLegalNotices.css('display', 'none');

                if (subPassword) {
                    subPassword.css('display', 'block').animate({
                        opacity: 1
                    }, 600);

                    $(document).on('wLostpassFormSuccess', function () {
                        $('#subtitlePassword').css('display', 'none');
                    });
                }
            });
        }
    }
};

//Utilisé dans le rayon pour déclencher la lightbox "article indisponible" lors du clic sur le visuel du produit
function displayInfoStock(obj) {
    $(obj).siblings('.wrap_rolloverproduit').find('.button.achat.valid').click();
}

/**
 * Pour afficher le PDF retour (transmis par le transporteur)
 * @param form
 * @param idOrder
 * @param multiTransp
 * @param numRetour
 * @param show_pdf
 */
function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf) {

    var data = $('#return_form').serialize();
    data += '&idOrder=' + idOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;

    $.ajax({
        url: path_relative_root + 'ajax_create_etiquette.php',
        type: 'post',
        data: data,
        success: function (res) {
            if (res !== 'false') {
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            } else {
                alert(Translator.translate('error_generate_label'));
            }
        }
    });
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop)
{
    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;

    if (from_type == 'product') {
        generateNewBlocProd(type, idObj, bloc_page, null, null, fromScrollBottom, fromScrollTop, true);
    } else {
        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}

function paginate(page) {
    var form = document.getElementById('filters_form'),
        totalElems = parseFloat($('#totalElems').val()),
        nb_prod = parseFloat($('#nb_prod').val()),
        nb_total_page = Math.ceil(totalElems / nb_prod),
        nb_prod_default = parseFloat($('#nb_prod_default').val()),
        pagination = $('.pagination'),
        see_all = parseFloat($('#see_all').val());

    page = page || 1;

    $.ajax({
        url: path_relative_root + create_link('ajax_reload_pagination'),
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {
            var productLoaded = $('.item_container').length;
            var percentLoaded = (productLoaded * 100 / totalElems);

            pagination.each(function () {

                $('.nbProdDisplayed').html(productLoaded + ' ' + Translator.translate('textNumberProduct') + ' ' + totalElems);

                if (productLoaded >= totalElems) {

                    $('.loader_scroll').addClass('shaded');

                } else {

                    if ($('.loader_scroll').hasClass('shaded')) {
                        $('.loader_scroll').removeClass('shaded');
                    }
                }

                $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');
            });
        }
    });
}

function seeXProduct(nb_product, page)
{
    var nb_prod_default = parseFloat($('#nb_prod_default').val());

    //Pour afficher tout, on repart de la page courante
    if (nb_product === (nb_prod_default + 1)) {
        $('#scroll_origin').val($("#initial_page").val());
        $('#page').val(page);

        nb_product = nb_prod_default;
        $("#see_all").val("1");
        generateNewBlocProd(null, null, (page - 1));
        $("#initial_page").val(page);
    } else {
        var type = $('#type_tri').val();
        $("#see_all").val("0");
        $("#nb_prod").val(nb_product);
        $("#initial_page").val(1);

        // Si on est sur la page de recherche
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(type, null, 0);
        }
    }
}

/**
 * Get stock of all product of the page
 */
function getGlobalStock()
{
    var productIdsAll = $.find('[data-prod]');
    var productIdsNew = new Array();

    if (productIdsAll.length) {
        for (var indexId = 0; indexId < productIdsAll.length; indexId++) {
            var currentProductId = $(productIdsAll[indexId]).attr('data-prod');
            if (array_stock_product[currentProductId] == undefined) {
                productIdsNew.push(currentProductId);
            }
        }
    }

    $.ajax({
        url : path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
        type : 'post',
        data : {produit_id: productIdsNew},
        success : function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            if (res.success && res.result) {
                var result = res.result;

                // On stock les informations dans le tableau JS
                Object.entries(result).forEach(entry => {
                    const [idProduct, objProduct] = entry;
                    array_stock_product[idProduct] = objProduct;
                    processDisplayProductInList($('[data-prod="' + idProduct + '"]'), objProduct);
                });
            }
        }
    });
}

function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || $('#filters_form #type').val(),
        idObj = idObj || $('#filters_form #idObj').val(),
        nb_product = nb_product || parseFloat($('#filters_form #nb_prod').val()),
        startPage = parseInt($('#page_start').val()),
        is_from_page = false,
        key,
        output = {};

    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    if (fromScrollBottom && !fromScrollTop) {
        $("#page_start").val(startPage + 1);

        $("#initial_page").val(page + 1);
    }

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form', fromPagination);
    nb_product = nb_product || parseFloat($('#nb_prod').val());

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
        is_from_page = true;

    }

    if ($('#oneProduct').length && $('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    // Add misc values to output object
    output.type = type;
    output.parentPath = path_relative_root;
    output.idObj = idObj;
    output.nb_prod = document.getElementById('nb_prod_default').value;
    output.is_page = is_from_page;
    output.nb_tot_prod = $("#totalElems").attr("data-origin");

    if (typeof id_category_super != 'undefined') {
        output.id_category_super = id_category_super;
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    output.page = fromScrollBottom ? $('.item_container').length : 0;
    output.handle_push = 'false';

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    if ($("input[name='tri']:checked").length > 0) {
        output.tri = $("input[name='tri']:checked").val();
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_reload_products.php',
        type: 'get',
        data: output,
        success: function (res) {

            var old_elm = document.querySelector('.list_item');
            var content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {
                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();

            itm_length = parseFloat(res[1]);

            if ($('.rayonCategSlider').length && output.object_id) {

                $('.rayonCategSlider .menuitem').removeClass('actif');
                $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                var rayonInitialSlide;

                $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                    if ($(obj).hasClass('actif')) {
                        rayonInitialSlide = i;
                    }
                });

                rayonCategSlider.slideTo(rayonInitialSlide);
            } else {
                $('.rayonCategSlider .menuitem').removeClass('actif');
            }

            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);


            // site 1 -> fr/en, site 2 -> uk, site 3 -> us
            if (res.indexOf(8) != -1) {
                $("link[rel^='alternate'][hreflang^='en-fr']").attr("href", JSON.parse(res[8])[1].en);
                $("link[rel^='alternate'][hreflang^='en-gb']").attr("href", JSON.parse(res[8])[2].en);
                $("link[rel^='alternate'][hreflang^='en-us']").attr("href", JSON.parse(res[8])[3].en);
            }

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext,commentNext, 'next');

            if (content !== '') {
                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".loader_scroll").addClass('loading');

                } else {
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (one_product) {
                    $('div.item_container').addClass('full');
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {
                    toggleMenu('filters', 'right');
                }

                if (window.lazyload || typeof lazyload != 'undefined') {
                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }

                getWishlistProducts();
            }
        },
        complete: function () {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            if (!fromScrollBottom && !fromScrollTop) {
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: 10
                    }, 200);
                }, 250);
            }

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($('body.category').length) {
                    var origin = $('#scroll_items .item_container:not(.push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();
                }
            }

            getGlobalStock();

            setTimeout(function () {
                var loader = $('.button + .loader');
                if (loader.hasClass('loading')) {
                    loader.removeClass('loading');
                } else {
                    loader.parent().removeClass('loading');
                }

                if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {
                    var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                    toggleMenu(menuId);
                }
            }, 200);

            /**
             * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
             */

            var totalElems = document.getElementById('totalElems').value;
            var productLoaded = $('.item_container').length;
            var percentLoaded = (productLoaded * 100 / totalElems);

            $('.nbProdDisplayed').html(productLoaded + ' ' + Translator.translate('textNumberProduct') + ' ' + totalElems);

            if (productLoaded >= totalElems) {

                $('.loader_scroll').addClass('shaded');

            } else {

                if ($('.loader_scroll').hasClass('shaded')) {
                    $('.loader_scroll').removeClass('shaded');
                }
            }

            $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');
        }
    });
}

function generateNewBlocSearch(nb_product, page, btn, fromScrollBottom, fromScrollTop) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function (elm) {
        if ((elm.type === 'checkbox' || elm.type === 'radio')) {
            if (elm.checked && elm.value !== '') {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }
                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {
            if (elm.value !== '') {
                output[elm.name] = elm.value;
            }
        }
    });

    if (fromScrollTop || fromScrollBottom) {
        $(".loader_scroll").css({
            display: 'block'
        });
        $('#is_loading').val('1');
    }

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {
        form.nb_prod.value = nb_product;
    } else {
        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {

        form.page.value = output.page = page * nb_product;
        is_from_page = true;
    }

    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    $.ajax({
        url: path_relative_root + 'ajax_reload_search.php',
        type: 'get',
        data: output,
        success: function (res) {

            var new_elm = document.createElement('div'),
                old_elm = document.querySelector('.list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            if (content !== '') {

                if (!fromScrollBottom && !fromScrollTop) {
                    new_elm.className = 'list_item';
                    new_elm.innerHTML = content;
                    old_elm = document.querySelector('#scroll_items');
                    $(old_elm).find('list_item').remove();
                }

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".loader_scroll").show();
                } else {
                    backToTop();
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.appendChild(new_elm);
                }

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else if (fromScrollBottom) {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {

                    toggleMenu('filters', 'right');
                }

                if (window.lazyload) {

                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            }
        },
        complete: function () {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');

            setTimeout(function () {

                var loader = $('.button + .loader');

                if (loader.hasClass('loading')) {

                    loader.removeClass('loading');
                } else {

                    loader.parent().removeClass('loading');
                }

                var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');

                toggleMenu(menuId);
            }, 200);

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($('body.category').length) {
                    var origin = $('#scroll_items .item_container:not(.push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();

                    target.each(function () {
                        $(this).css({ minHeight: targetHeight });
                    })
                }
            }

            if (fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(page + 1);
                $(".loader_scroll").hide();
            }
        }
    });
}

function generateNewBlocFilter(type, idObj, page, nb_product, btn, typeFiltre, id) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        tmpoutput = {},
        is_from_page = false,
        key;

    var thisform = form;

    // Get all filters
    form = Array.prototype.slice.call(form.elements, 0);
    var keys = new Array();

    if (use_new_filter_url) {
        output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    } else {
        // Build an object to store active filters
        form.forEach(function (elm) {
            if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                    if (output[elm.name] === undefined) {
                        output[elm.name] = [];
                    }
                    output[elm.name].push(parseFloat(elm.value));
                }
                keys[elm.name] = 1;
            }
        });
        // Convert filters arrays to strings
        for (key in output) {

            if (output.hasOwnProperty(key)) {

                if (output[key].constructor === Array) {

                    output[key] = output[key].join('~');
                }
            }
        }
        if ($(".filter_date").length > 0) {

            output.multi_object = 'true';
            output.id_category_super = id_category_super;

            if (typeof (output['date']) == 'undefined') {
                output.date = 4;
            } else {
                if (output['date'] == 4)
                    output['date'] = tmp_date;
            }
        } else {
            if (output['date'] == 4) {
                output['date'] = tmp_date;
            }
        }

        if (nb_product !== undefined) {

            this.nb_prod.value = nb_product;
        } else {

            nb_product = this.nb_prod.value;
        }

        // Add misc values to output object
        output.page = this.page.value;
        output.type = type;
        output.parentPath = path_relative_root;
        output.idObj = idObj;
        output.nb_prod = nb_product;
        output.is_page = is_from_page;
        output.nb_tot_prod = this.totalElems.value;
        output.typeFiltre = typeFiltre;

    }

    /** LEGACY **/

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_change_filtre_produit.php',
        type: 'get',
        data: output,
        success: function (response) {

            var resultats = jQuery.parseJSON(response);

            var typeFilter = resultats.filter;
            var array_size = resultats.size;
            var array_colors = resultats.color;
            var array_brand = resultats.brand;
            var array_eclat = resultats.eclat;
            var array_store = resultats.store;

            if (typeFilter == 'sscat' && $('.container_products_lookbook').length == 0) {
                if (array_colors.length == 0 || array_size.length == 0) {
                    changeajaxfilterRayon(thisform, 'all', id, true);
                    return true;
                }
            }

            if (typeFilter != 'taille') {
                generateListFilter('taille', array_size, true);
            }

            if (typeFilter != 'couleur') {
                generateListFilter('couleur', array_colors, true);
            }

            if (typeFilter != 'brand') {
                generateListFilter('promo', array_brand, true);
            }

            if (typeFilter != 'eclat') {
                generateListFilter('eclat', array_eclat, true);
            }

            if (typeFilter != 'store') {
                generateListFilter('store', array_store, true);
            }

            /* Nombre de produits */
            // Get all filters
            form = Array.prototype.slice.call(thisform.elements, 0);
            // Build an object to store active filters
            form.forEach(function (elm) {
                if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                    if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                        if (tmpoutput[elm.name] === undefined) {
                            tmpoutput[elm.name] = [];
                        }

                        if (elm.name == 'charact') {
                            tmpoutput[elm.name].push(elm.value);
                        } else {
                            tmpoutput[elm.name].push(parseFloat(elm.value));
                        }
                    }
                }
            });

            // Convert filters arrays to strings
            for (key in keys) {
                if (tmpoutput.hasOwnProperty(key)) {
                    output[key] = tmpoutput[key].join('~');
                } else {
                    delete output[key];
                }
            }

            if ($(".filter_date").length > 0) {

                output.multi_object = 'true';
                output.id_category_super = id_category_super;

                if (typeof (output['date']) == 'undefined') {
                    output.date = 4;
                } else {
                    if (output['date'] == 4)
                        output['date'] = tmp_date;
                }
            } else {
                if (output['date'] == 4) {
                    output['date'] = tmp_date;
                }
            }

            bloc_prod_xhr = $.ajax({
                url: path_relative_root + 'ajax_reload_products.php',
                type: 'get',
                data: output,
                success: function (res) {
                    var new_elm = document.createElement('div'),
                        old_elm = document.getElementById('scroll_items'),
                        content, itm_length;

                    res = res.split('><><');

                    content = res[0].trim();

                    if (res[1] !== '') {
                        itm_length = parseFloat(res[1]);

                        //Permet d'actualiser lors de l'application d'un filtre, le nombre de produits total restants après application
                        //Info acutalisée en haut du filters_menu - potentiellement en display:none;
                        document.getElementById('nbr_items').innerHTML = itm_length;
                        document.getElementById('totalElems').value = itm_length;
                        $("#filters_form_submit span").html(Translator.translate("apply_item_filters") + " (" + itm_length + ")");

                        if (window.lazyload) {

                            lazyload.init();
                        }
                    }
                    $('#is_loading').val('0');
                }
            });
        }

    });
}

var eresa_no_stock = true;

/** Remplace format image, du format par défaut au format désiré lors du changement de vue dans le rayon */
function switchVisu() {
    var src_format = $('.list_item').length && $('.item_container').hasClass('full') ? 'mini':'medium';
    var dest_format = $('.list_item').length && $('.item_container').hasClass('full') ? 'medium':'mini';

    $('.list_item .item_container img').each(function () {
        if (typeof($(this).attr('src'))!='undefined') {
            $(this).attr('src',$(this).attr('src').replace(src_format,dest_format));
        }
    });

    $('.list_item .item_container span').each(function () {
        if (typeof($(this).attr('data-src'))!='undefined') {
            $(this).attr('data-src',$(this).attr('data-src').replace(src_format,dest_format));
        }
    });
    setTimeout(function() {
        initItemSlider(true);
    }, 200);

}

// v2 code
function toggleViewMobile() {
    $( 'div.toggleNumObjects span' ).toggleClass( 'actif' );

    if ( $("#oneProduct").hasClass('actif')) {
        $('.twoProducts').removeClass('actif');
        $('div.item_container').addClass('full');
        createCookie( 'nb_produits', 1, 1 );
    } else {
        $('div.item_container').removeClass('full');
        $('.oneProduct').removeClass('actif');
        createCookie( 'nb_produits', 2 );
    }
    initItemSlider(true);
}

// popup alerte taille
function showAlertSizeBox(taille){
    var alertSizeBoxText = document.getElementById('alertSizeBoxText');
    alertSizeBoxText.innerHTML = taille;
    modBoxOpen.call(document.getElementById('alertSizeBox'))
}

function show_lightbox_3xcb()
{
    // Commenté et return car view masterisée
    // openMultiShad( 'popup_cofidis3cb' );
    return
}

/**
 * Permet de calculer les mensualités d'un paiement Cofidis 3x
 * @param {string} amount
 */
function calculCofidis3Cb()
{
    var amount = $('#montantCalcul').val();
    if (amount > 0) {
        $.ajax({
            url : 'https://www.simulateurcofidis.com/3cb/index.php',
            type : 'get',
            data : {'montant': amount, 'key': 'RlX3lf1f98Cal6r0v1ul7Phq7YfJSdnybnvuXuL6T9RaVGhRyr', 'defaut': 0, 'retour': 'flux'},
            success : function (response) {
                var resJson = JSON.parse(response);
                var montant = Number.parseFloat(resJson.montant).toFixed(2).toString().replace('.', ',');
                var mensualite = Number.parseFloat(resJson.mensualite).toFixed(2).toString().replace('.', ',');
                var derniere_mensulatie = Number.parseFloat(resJson.derniere_mensulatie).toFixed(2).toString().replace('.', ',');
                var montant_du = Number.parseFloat(resJson.montant_du).toFixed(2).toString().replace('.', ',');
                $('#tab_mensualites .montant .tab_amount span').html(montant);
                $('#tab_mensualites .mensualite_1 .tab_amount span').html(mensualite);
                $('#tab_mensualites .mensualite_2 .tab_amount span').html(derniere_mensulatie);
                $('#tab_mensualites .montant_du .tab_amount span').html(montant_du);
                $('#tab_mensualites .frais .tab_amount span').html(resJson.frais);
            }
        });
    }
}

function cofidisScrollbar() {
    // Wait for _app modBoxOpen to open lightbox
    setTimeout(function() {
        if ($('#popup_cofidis3cb .popup_container').length) {
            $('#popup_cofidis3cb .popup_container').overlayScrollbars({});
        }
    }, 800);
}

function submitToCalcul(e) {
    if (e.keyCode && e.keyCode == "13") {
        calculCofidis3Cb();
    } else {
        return;
    }
}

function showCtaAvailableFaq() {
    if ($('.product_page').length && $('.cta_availability_wrapper').length) {
        $('.cta_availability_wrapper').show();
    }

    // Hide FAQ theme_reponse when global E is inactive
    if ($('.faq .theme_reponse').length) {
        $('.theme_reponse').show();
    }
}

// Updates visual and thumbnail for selected color
function updateColor() {

    var item_visu = document.getElementById('img_large');
    var colr_visu = document.getElementById('preview_color');
    var item_url = this.getAttribute('data-itm-img');
    var colr_url = path_relative_root + 'img/couleur/' + this.value + '.jpg';
    var colr_hexa = this.getAttribute('data-hexa');
    var is_model = this.getAttribute('data-ismodel');
    var has_photo = this.getAttribute('data-color-img');

    if (has_photo == '0') {
        has_photo = false;
    }

    if (this.value != '' && colr_visu) {

        if (!$('body').hasClass('product_page')) {
            if ($('#img_large.product_picture').length > 0) {
                $('#img_large.product_picture').each(function(index) {
                    $(this).attr('src', item_url);
                });
            } else {
                item_visu.src = item_url;
            }
        }

        colr_visu.removeAttribute('style');

        if (colr_hexa) {

            if (colr_hexa.length > 0) {
                colr_visu.setAttribute("style", "background-color : " + colr_hexa);
            }
        } else {

            if (is_model) {
                colr_visu.setAttribute("style", "background:url(" + item_url + ") center;background-size:cover");
            } else if (has_photo) {
                colr_visu.setAttribute("style", "background:url(" + has_photo + ") ");
            } else {
                colr_visu.setAttribute("style", "background:url(" + colr_url + ") center");
            }
        }

        colr_visu.css;

        $(colr_visu).show();
    } else {

        $(colr_visu).hide();
    }

    if (initialize_cta && $('.product_page').length && $('.cta_availability_wrapper').length) {
        if (typeof GEM_Services != "undefined") {
            try {
                var IsOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
                // if !ifOperatedByGlobalE --> Not Active or FR website
                if (!IsOperatedByGlobalE) {
                    showCtaAvailableFaq();
                }
            } catch (e) {}
        } else {
            showCtaAvailableFaq();
        }
    }
}

/**
 *
 * @param productId : l'id du produit
 * @param itm_rr_id : l'id du regroupement
 * @returns {boolean}
 */
function checkProductInWishlist() {

    var productId = $("#produit_id").val();
    var colorId = 0;
    var couleurProdElt = $("#prod_couleur");
    if (couleurProdElt.length) {
        colorId = couleurProdElt.val();
    }
    var sizeId = 0;
    var tailleProdElt = $("#prod_taille");
    if (tailleProdElt.length) {
        sizeId = tailleProdElt.val();
    }

    if (typeof productId != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data:{'product_id': productId, 'size_id': sizeId, 'color_id': colorId},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 * Aurelien Renault
 * @param in_wishlist : true ou false selon si dans wishlist
 * @param product_id : l'id du produit
 * @param itm_rr_id : l'id du regroupement
 */
function changeBtnAddWishlist(in_wishlist) {

    var btn = $("#addToWishlistButton");
    if (in_wishlist) {
        btn.attr("onclick", "");
        btn.attr("href", path_relative_root + create_link('wishlist'));
        btn.addClass("existToWishlistButton");
    } else {
        btn.attr("onclick", 'addToWishlist.call(this);return false;');
        btn.attr("href", "#");
        btn.removeClass("existToWishlistButton");
    }
}

/* FUNCTION PUSH INTO DATALAYER  */
function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

function reloadProductBlocNoResults() {
    var content = '<div class="no_results_wrapper">' +
                    '<div class="search_details_wrapper">' +
                        '<div class="no_result_block">' +
                            Translator.translate('no_result_txt') +
                        '</div>' +
                    '</div>' +
                '</div>';
    $('#scroll_items .list_item').html(content);
}

/**
 * Loads product data and updates the page content accordingly.
 * @param {string} type_filter - The type of filter.
 * @param {number} [id] - The ID of the product (optional).
 * @param {string} [type_page='product'] - The type of page (optional).
 */
function loadProduct(type_filter, id, type_page) {
    var customButton = document.querySelector('.btn_container.btn_personalize button');
    var btnAddToCart = document.getElementById("button_add");
    btnAddToCart.disabled = true;

    if (typeof(id) == 'undefined') {
        id_prod = "";
    } else {
        id_prod = "_" + id;
    }

    if (typeof(type_page) == 'undefined') {
        type_page = 'product';
    }

    var $form = $( '#item_add_form' );
    var $input = $( '#prod_' + type_filter + id_prod, $form );
    var type = type_filter;
    var $hidden = $( '#prod_' + type_filter + id_prod, $form );
    var tailleId = $("#prod_taille").val();
    if (tailleId && customButton) {
        var customText = customButton.querySelector('.text_personalize');
        customText.style.color = 'black';
    } else if (customButton) {
        var customText = customButton.querySelector('.text_personalize');
        customText.style.color = 'gray';
        customButton.addEventListener('click', (e) => {
            if (tailleId == '') {
                customText.style.color = 'gray';
                var itm_size = document.getElementById('prod_taille');
                itm_size.classList.add('error');
                e.preventDefault();
                e.defaultPrevented = false;
            }
        });
    }

    $produit_id_new = $('#prod_' + type_filter + id_prod).find(':selected').attr('data-produitid');
    if (typeof($produit_id_new) == 'undefined') {
        if (type_filter == 'taille') {
            $produit_id_new = $('#prod_taille option:selected').data('produit_id');
        } else {
            $produit_id_new = $('#prod_couleur option:selected').data('produitid');
        }
    }

    var data = '';
    if (typeof(id) == 'undefined') {
        data += $form.serialize();
    }
    data += '&produit_id_new=' + $produit_id_new;
    data += '&couleurProd=' + $("#prod_couleur").val();
    data += '&tailleProd=' + tailleId;
    data += '&type=' + type_filter;
    data += '&type_page=' + type_page;

    if (document.getElementById('wishlist')) {
        data += '&type_page=' + type_page;
    }

    if (typeof(id) != 'undefined') {
        data += '&couleurProd=' + $('#couleurProd'+id_prod).val();
        data += '&tailleProd=' + $('#tailleProd'+id_prod).val();
        data += '&produit_principal=' + $('#produit_principal'+id_prod).val();
        var produit_principal = $( '#produit_principal'+id_prod).val();
    }

    if (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) {
        data += '&warehouse_only=1';
    }

    var produit_id = $("#produit_id").val();
    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        beforeSend: function(){
            $('#bloc_btn_loader').show();
            $('#btn_error').fadeOut(300);
        },
        success: function(response) {
            try {
                response = $.parseJSON(response);
            } catch(e) {
                console.error( 'parseJSON' );
                return;
            }

            if (response.success == 'ok' ){
                var datas = response.datas;

                if (type_page == 'product') {
                    var $html_main = $(datas.html_main);
                    var $fiche = $( '#contents_wrapper');
                    var replace_main = new Array(
                        '#wrapper_product_name',
                        '#zoom_container',
                        '.prod_page_bot',
                        '.compo'
                    );

                    for (var i = 0; i < replace_main.length; i++) {
                        var id = replace_main[i];
                        $(id, $fiche).html($( id, $html_main ).html());
                    }

                    var carousel_wrapper = $('#carousel_wrapper', $html_main);

                    if (carousel_wrapper.length > 0) {
                        $('#carousel_wrapper').html(carousel_wrapper.html());
                    } else {
                        $('#carousel_wrapper').html('');
                    }

                    if ($('#zoom_container .swiper-slide').length > 1) {
                        var mainSlider = new Swiper('#zoom_container .swiper-container', {
                            slidesPerView: 1,
                            pagination: '#zoom_container .swiper-pagination',
                            paginationClickable: true
                        });
                    }

                    if ($('.ranges_slider').length) {
                        var ranges_slider = new Swiper('.ranges_slider', {
                            slidesPerView: 2,
                            spaceBetween: 12
                        });
                    }

                    if ($('.assoc_slider').length) {
                        var assoc_slider = new Swiper('.assoc_slider', {
                            slidesPerView: 2,
                            spaceBetween: 12
                        });
                    }

                    window.prod_slider = document.getElementById("product_pictures_slider");
                    window.asso_slider = document.getElementById('asso_product_carousel');
                    window.gamm_slider = document.getElementById('gamme_product_carousel');

                    if (asso_slider && $('.swipe_slider_visu', asso_slider).length > 0) {
                        settings_asso = {
                            speed : 200,
                            easing : 'linear',
                            slideWidth: window.innerWidth / count_slider_product,
                            minSlides : 1,
                            maxSlides : count_slider_product,
                            moveSlides : 1,
                            pager : false,
                            onSliderLoad : onAssoSliderLoad,
                            onSlideBefore : onAssoSlideBefore,
                            onSlideAfter : onAssoSlideAfter
                        };
                        $(asso_slider).closest('.bx-wrapper').css('max-width', window.innerWidth);
                        window.addEventListener('resize', resizeAssoSlider);
                    }
                    if (gamm_slider && $('.swipe_slider_visu', gamm_slider).length > 0) {
                        settings_gamm = {
                            speed : 200,
                            easing : 'linear',
                            slideWidth: window.innerWidth / count_slider_product,
                            minSlides : 1,
                            maxSlides : count_slider_product,
                            moveSlides : 1,
                            pager : false,
                            onSliderLoad : onGammSliderLoad,
                            onSlideBefore : onGammSlideBefore,
                            onSlideAfter : onGammSlideAfter
                        };
                        $(gamm_slider).closest('.bx-wrapper').css('max-width', window.innerWidth);
                        window.addEventListener('resize', resizeGammSlider);
                    }

                    if (prod_slider && $(".swiper-container", prod_slider).length > 1) {
                        settings = {
                            speed : prod_slider.offsetWidth * 0.8,
                            easing : "linear",
                            pager : false,
                            onSliderLoad : function (currentSlide, totalSlides, currentSlideHtmlObject) {
                                $("#product_pictures_slider > li > div > img").removeClass("active-slide");
                                $("#product_pictures_slider > li > div > img").eq(currentSlideHtmlObject + 1).addClass("active-slide")
                            },
                            onSlideBefore : function (currentSlide, totalSlides, currentSlideHtmlObject) {
                                $("#product_pictures_slider > li > div > img").removeClass("active-slide");
                                $("#product_pictures_slider > li > div > img").removeAttr("id");
                                $("#product_pictures_slider > li > div > img").eq(currentSlideHtmlObject + 1).addClass("active-slide");
                                $("#product_pictures_slider > li > div > img").eq(currentSlideHtmlObject + 1).attr("id", "img_large");
                            }
                        };
                        window.addEventListener("resize", resizeSlider);
                    } else {
                        delete window.prod_slider;
                    }

                    var string_params = '';
                    if ($("#produit_lot_nb").val() != undefined) {
                        string_params += '&produit_lot_nb=' + $("#produit_lot_nb").val();
                    }

                    if (tailleId) {
                        $('#prod_taille [value=' + tailleId + ']').prop('selected', true);
                    }

                    if ($('#prod_taille').val() > 0 && $('#prod_taille').find('option:selected').attr('is-disabled-entrepot') == '0') {
                        // reload qty
                        var qte = ajax_file(path_relative_root + create_link('ajax_liste_quantity') + '?idprod=' + datas.product.id + '&idcouleur=' + datas.product.color.id + '&idtaille=' + $('#prod_taille').val() + string_params);
                        $('#liste_qte').html(qte);
                    }

                    var contentMovable = $('#content_movable');

                    $('.jqzoom').on('doubletap', function () {
                        zoomImg.call(this);
                        $('#zoomBox').addClass('active');

                        contentMovable.addClass('visible');
                        var zoomboxWidth = $('body').width(),
                            scrollTo = zoomboxWidth / 2;
                        setTimeout(function(){
                            $('#zoomBox').scrollLeft(scrollTo);
                        }, 'slow');
                    });

                    contentMovable.on('doubletap', function () {
                        $(this).removeClass('visible');
                        $('#zoomBox .btn_close').trigger('click');
                    });

                    if ($("#path_personalize").length) {
                        var path = $("#path_personalize").attr('href');
                        var replace = new RegExp(produit_id);
                        var new_path = path.replace(replace, datas.product.id);
                        $("#path_personalize").attr('href', new_path);
                    }
                    var option_selected = false;
                    var option_value = '';
                    if ($('#prod_taille').length) {
                        $('#prod_taille option').each(function() {
                            if ($(this).is(':selected')) {
                                if ($(this).val() !== '') {
                                    option_selected = true;
                                    option_value = $(this).val();
                                }
                                return option_selected, option_value;
                            }
                        });
                    }

                    var hasStock = false;
                    var stocks = response.stock;

                    $.each(stocks, function (index, stock) {
                        if (typeof(stock) == 'object') {
                            $.each(stock, function (key, val) {
                                if (val === true) {
                                    hasStock = true;
                                }
                            });
                        }
                    });

                    var currentColor = $('#prod_couleur').val();
                    var currentSize = $('#prod_taille').val();

                    if (parseInt(currentSize) > 0 && parseInt(currentColor) > 0 && stocks != null) {
                        hasStock = stocks[currentColor][currentSize];
                    }

                    var old_text = $('#prod_taille option[value="' + currentSize + '"]').text();

                    if (hasStock === true) {
                        btnAddToCart.disabled = false;
                        // if we don't have stock, we display the "alert" button
                        $("#bloc_add_alert").hide();
                        // we hide the “add to cart” button
                        $("#bloc_add_basket").show();
                        $("#add_basket_wrapper").show();
                        $(".btn_personalize").show();

                        var new_text = old_text.replace(receive_alert, '');
                        $('#prod_taille option[value="' + currentSize + '"]').text(new_text);

                        // otherwise, we hide them
                    } else {
                        btnAddToCart.disabled = true;
                        // if we don't have stock, we display the "alert" button"
                        $("#bloc_add_alert").show();

                       // if alerte stock not set
                        if (!datas.product.enableAlertStock) {
                            $("#bloc_size_not_available_" + datas.product.id).html(Translator.translate("size_is_not_available"));
                            $("#bloc_size_not_available_" + datas.product.id).show();
                        }
                        // we hide the “add to cart” button

                        $("#bloc_add_basket").hide();
                        $("#add_basket_wrapper").hide();
                        $(".btn_personalize").hide();


                        if (old_text.indexOf(receive_alert) <= 0 && datas.product.enableAlertStock) {
                            var new_text = old_text + receive_alert;
                            $('#prod_taille option[value="' + currentSize + '"]').text(new_text);
                        }
                    }

                } else if (type_page == 'look') {

                    var $html_main = $(datas.main_content);

                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {

                        array_look.splice(i, 1);
                    }

                    // Once the information is loaded we will update the quantity select with the new chosen product
                    showQteListeGamme('liste_qte_' + datas.product.id,  datas.product.id, path_relative_root);

                } else if (type_page == 'assoc') {

                    var $html_main = $(datas.html_main);
                    $('#ref_assoc_' + produit_principal).html($('#ref_assoc_' + produit_principal, $html_main).html());

                    // Once the information is loaded we will update the quantity select with the new chosen product
                    showQteListeGamme('liste_qte_' + datas.product.id,  datas.product.id, path_relative_root);
                }

                // init alma
                if (document.getElementById("alma-widget") !== null) {
                    initAlmaWidget();
                }

                // input style reload
                $('.selectStyled').each(function(){
                    $(this).selectStyled();
                });

                setTimeout(function() {
                    $(".zoom_indicator").fadeOut(800);
                }, 2500);
            } else {
                $('#btn_error').fadeIn();
                if (response.error.msg == 'unavailable_product') {
                    // if we don't have stock, we display the "alert" button"
                    if ($("#bloc_add_alert") !== undefined) {
                        $("#bloc_add_alert").show();
                    }
                    // we hide the “add to cart” button
                    if ($("#bloc_add_basket") !== undefined) {
                        $("#bloc_add_basket").hide();
                    }
                    if ($("#add_basket_wrapper") !== undefined) {
                        $("#add_basket_wrapper").hide();
                    }
                }
            }
        },
        complete: function() {
            $('#bloc_btn_loader').fadeOut(300);

            if (window.lazyload !== undefined) {
                lazyload.init();
            }

            var btnAddToCart = document.getElementById("button_add");
            if (!$('#prod_taille').val()) {
                btnAddToCart.disabled = true;
            }

            checkSizeWpos();
            onLoadProductFormNew();

            // BEGIN Apple Pay Express Checkout Product
            if (typeof initApplePayExpressCheckout == 'function') {
                initApplePayExpressCheckout();
            }

            if (typeof initApplePayExpressCheckoutFloatingAdd == 'function') {
                initApplePayExpressCheckoutFloatingAdd();
            }
            // END Apple Pay Express Checkout Product
        }
    });
}

// Suppression d'un produit dans la wishlist
function removeFromWish(removeBtn) {
    if (!$(removeBtn).hasClass("loading")) {
        $(removeBtn).closest('.cart_item_actions_content').addClass('loading');
    }

    var id = $(removeBtn).attr("data-id");

    $.post("ajax_remove_from_wishlist.php", {
        id: id
    }, function(data) {
        var wproduct = removeBtn.closest(".cart_item_container");

        $(wproduct).fadeOut(100, function() {
            wproduct.remove();
        });

        //update nb_products wishlist
        var nbWishlist = data.result.wishlistLength;
        var nb_products_wishlist;

        if (typeof nr === "undefined") {
            nbWishlist = $(".cart_item_container.wishlist").length
        }
        if (nbWishlist <= 1) {
            nb_products_wishlist = "<span class=\"parenthesis\">(</span>" + nbWishlist + "<span>" + Translator.translate("one_product") + "</span><span class=\"parenthesis\">)</span>";
        } else {
            nb_products_wishlist = "<span class=\"parenthesis\">(</span>" + nbWishlist + "<span>" + Translator.translate("2_product") + "</span><span class=\"parenthesis\">)</span>";
        }
        if (nbWishlist == 0) {
            $(".wishlist.sub_tunnel").addClass('empty');
            $('.tunnel_step_title.wishlist').hide();
            $("#menu_account_wishlist .highlight").html('');
        } else {
            $("#menu_account_wishlist .highlight").html('(' + nbWishlist + ')');
        }
        $(".title_basket_tunnel.tunnel_step_title .nb_products").html(nb_products_wishlist);

        hideModalAction();
    });
}

if ($('.container_block_cabas .swiper-slide').length > 1 ) {

    setTimeout(function() {
        accountSswiper = new Swiper('.container_block_cabas .swiper-container', {
            slidesPerView: 2.85,
            spaceBetween: 4,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }
        } );
    }, 800);
}

function addToWishlistUpdateHeader(nr) {

    if (typeof nr === "undefined") {
        nr = $(".cart_item_container.wishlist").length;
    }

    if (typeof nr !== "undefined") {
        if (nr == 0) {
            $("#menu_account_wishlist").hide();
        } else {
            $("#menu_account_wishlist").show();
            $("#menu_account_wishlist .highlight").html('( ' + nr + ' )');
        }
    }
}

function toggleWishList() {
    addToWishlistUpdateHeader();
}

function toggleIndispensable() {

}

function updateCartElements() {
    addToWishlistUpdateHeader();
    cartSwipeGondole();
    $('#right_col_sticky .bloc_question').remove();
    // Check if the unit value upselling_price is 0
    if ($('#threshold_fdp_txt .unit').text() == '0') {
        $('.upselling.price').css('display', 'none');
    }
    btnConfirmSticky();
}

function onLoadProductFormNew(hide_bookinstore, date_arrivage) {
    var produit_id = $('#produit_principal').val();
    var arrayProduit = [];
    var arrayCabas = [];
    if (typeof arrayIdCabas != 'undefined' && arrayIdCabas.length > 0) {
        arrayProduit = arrayIdCabas;
        arrayCabas = arrayIdCabas;
    } else {
        arrayProduit = [produit_id];
    }
    var data = {produit_id: arrayProduit};
    var productSize = document.getElementById("prod_taille");

    if (typeof btnAddToCart == 'undefined') {
        var btnAddToCart = document.getElementById("button_add");
    }
    data.valueAsBool = 0;
    setTimeout(function() {
        if (isGlobalEActive) {
            data.warehouse_only = 1;
        }

        $.ajax({
            url: path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
            type: 'post',
            data: data,
            success: function (res) {
                try {
                    res = $.parseJSON(res);
                } catch (e) {
                    console.error("parseJSON");
                    btnAddToCart.style.opacity = "0.5";
                    btnAddToCart.style.cursor = "not-allowed";
                    btnAddToCart.disabled = true;

                    return;
                }

                if (res.success && res.result) {
                    var has_stock = false;
                    var length_item_color = $('.product_form  [name="itm_colour"]').length;

                    if (length_item_color) {
                        var sizes_tab = res.result[produit_id][$("#prod_couleur").val()];
                    } else {
                        var sizes_tab = res.result[produit_id][0];
                    }

                    var size_box, is_disabled;
                    for (size_id in sizes_tab) {
                        if (sizes_tab.hasOwnProperty(size_id)) {
                            var old_text = $('#prod_taille option[value="' + size_id + '"]').text();
                            var produit_flag_alert_stock = res.result[produit_id]['enableAlertStock'];
                            if (sizes_tab[size_id] != 0) {
                                has_stock = true;
                                var new_text = old_text.replace(receive_alert, '');

                                if (sizes_tab[size_id] >= 1 && sizes_tab[size_id] <= 5) {
                                    new_text = new_text + ' - ' + Translator.translate('last_taunt');
                                }
                                $('#prod_taille option[value="' + size_id + '"]').text(new_text);
                            } else {
                                if (old_text.indexOf(receive_alert) <= 0 && produit_flag_alert_stock) {
                                    var new_text = old_text + receive_alert;
                                    $('#prod_taille option[value="' + size_id + '"]').text(new_text);
                                }
                            }
                        }
                    }
                    
                    if (typeof arrayIdCabas != 'undefined' && arrayIdCabas.length > 0) {
                        arrayIdCabas.forEach(function (productId) {
                            var produitFlagAlertStock = res.result[productId]['enableAlertStock'];
                            var sizeId = Object.keys(res.result[productId][$("#prod_couleur").val()])[0];
                            var stock = res.result[productId]['stock_quantite'];
                            var oldText = $('#prod_taille option[value="' + sizeId + '"]').text();
                            
                            if (stock != 0) {
                                var newText = oldText.replace(receive_alert, '');

                                if (oldText.indexOf(Translator.translate('porte_cabas')) != -1) {
                                    newText = oldText.replace(Translator.translate('porte_cabas'), 'L');
                                }

                                if (stock >= 1 && stock <= 5 && oldText.indexOf(Translator.translate('last_taunt')) == -1) {
                                    newText = newText + ' - ' + Translator.translate('last_taunt');
                                }

                                $('#prod_taille option[value="' + sizeId + '"]').text(newText);
                            } else {
                                if (oldText.indexOf(receive_alert) <= 0 && produitFlagAlertStock) {
                                    var newText = oldText + receive_alert;
                                    $('#prod_taille option[value="' + sizeId + '"]').text(newText);
                                }
                            }
                        })
                    }

                    if (document.getElementById('prod_taille') && $('#prod_taille').val()> 0) {
                        has_stock = sizes_tab[$('#prod_taille').val()];
                    }

                    if (isGlobalEActive) {
                        btnAddToCart.style.opacity = "0.5";
                        btnAddToCart.style.cursor = "not-allowed";
                        btnAddToCart.disabled = true;
                    } else {
                        if (has_stock && has_stock != 0) {
                            btnAddToCart.disabled = false;
                            btnAddToCart.style.opacity = "1";
                            btnAddToCart.style.cursor = "default";
                            $("#add_basket_wrapper").show();
                            $("#link_book_in_store").show();
                            $(".stock_check").show();
                            $("#bloc_add_alert").hide();
                        } else {
                            btnAddToCart.style.opacity = "0.5";
                            btnAddToCart.style.cursor = "not-allowed";
                            btnAddToCart.disabled = true;

                            if (hide_bookinstore == 1) { // if we want to hide CTA "book in store"
                                $("#link_book_in_store").hide();
                            }
                            $(".stock_check").hide();

                            if (date_arrivage != undefined && date_arrivage.length > 0 && date_arrivage != "0000-00-00 00:00:00") {
                                $("#bloc_add_alert").show();
                            }
                        }
                    }
                }
            }
        });


    }, 3000);
    var customButton = document.querySelector('.btn_container.btn_personalize button');
    var productSize = document.getElementById("prod_taille");
    if (customButton) {
        var customText = customButton.querySelector('.text_personalize');
        if (productSize.value == '') {
            customText.style.color = "gray";
        }
        customButton.addEventListener('click', (e) => {
        if (productSize.value == '') {
            var itm_size = document.getElementById('prod_taille');
            itm_size.classList.add('error');
            e.preventDefault();
            e.defaultPrevented = false;
            }
        });
    }
}

function revealModInfo(from) {

	var mod_info = $(this).prevAll('.mod_info');

	$('#is_magasin').val(from);

	if (mod_info.length > 0) {

		if (mod_info.is(':visible')) {

			mod_info.slideUp(200);
			$(this).children('span').html($(this).attr('data-open'));
			$(this).removeClass('cancel');
            $('.bloc_horaires').show();
		} else {

			mod_info.slideDown(200);
			$(this).children('span').html($(this).attr('data-close'));
			$(this).addClass('cancel');
            $('.bloc_horaires').hide();
		}
	}
}

// Pretty straightforward: this formats a price
var formatPrice = (function () {

    var THREEDIGITS = new RegExp("\\d{3}", "g");
    var FORMAT = window.PRICE_FORMAT_PLAIN_JS;

    // Splits a number into chunks of 3 digits
    function splitThousands(nmbr) {

        var f_chunk = '', chunk_length;

        nmbr = String(nmbr);

        chunk_length = nmbr.length % 3;

        if (chunk_length) {

            f_chunk = nmbr.slice(0, chunk_length);
            nmbr = nmbr.substr(chunk_length);
        }

        return f_chunk + nmbr.replace(THREEDIGITS, function (match) {
            var CURRENCY = window.siteCurrency;
            var thousandSep = CURRENCY.thousands_sep ?? ' ';
            return thousandSep + match;
        });
    }

    return function (price) {
        var CURRENCY = window.siteCurrency;
        var FORMAT = window.PRICE_FORMAT_PLAIN_JS;

        var output;

        if (price.constructor !== String) {

            price = price.toString(10);
        }

        price = price.replace('.', ',');
        price = price.split(',');

        if (price[1] === undefined) {
            price[1] = '';
        }

        if (typeof window.PRICE_FORMAT_PLAIN_JS_NO_CENTS != 'undefined' && window.PRICE_FORMAT_PLAIN_JS_NO_CENTS != 'PRICE_FORMAT_PLAIN_JS_NO_CENTS') {
            if (price[1] === '00' || price[1] === '') {
                FORMAT = window.PRICE_FORMAT_PLAIN_JS_NO_CENTS;
            }
        }

        output = FORMAT.replace('[<unit>]', splitThousands(price[0]));
        output = output.replace('[<symbol_right>]', CURRENCY.symbol_right);
        output = output.replace('[<symbol_left>]', CURRENCY.symbol_left);
        output = output.replace('[<decimal>]', price[1]);
        output = output.replace('[<code>]', CURRENCY.code);

        return output;
    };
}());

var initialize_cta = false;
$(document).ready(function () {
    setTimeout(function() {
        // avoid to check in updateColor after loading product the first
        initialize_cta = true;
        // Global E -> disablings when active
        if (typeof GEM_Services != "undefined") {
            try {
                var isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
            }
            catch (e) {
                var isOperatedByGlobalE = false;
            }

            // if !ifOperatedByGlobalE --> Not Active or FR website
            if (!isOperatedByGlobalE) {
                showCtaAvailableFaq();
            }
        } else {
            showCtaAvailableFaq();
        }
    }, 2500);

    setTimeout(function () {
        fix_total_panier();
    }, 100);

    $('.mot_personnalise_form').click(function () {
        if ($('#mot_personnalise').css('display') == 'none') {
            var new_position = $(".elem_tunnel.right_side").offset().top + 50;

            $('html, body').animate({
                scrollTop: new_position
            }, 'slow');
        }

        fix_total_panier();
        unbind_bin_sticky();

        setTimeout(function () {
            unbind_bin_sticky();
            fix_total_panier();
        }, 200);

        setTimeout(function () {
            unbind_bin_sticky();
            fix_total_panier();
        }, 300);

        setTimeout(function () {
            fix_total_panier();
            unbind_bin_sticky();
        }, 800);
    });

    document.onreadystatechange = function () {
        //Check when the document is fully loaded with global-e
        if (document.readyState == "complete") {

            if (typeof GEP != 'undefined' && typeof GEP.isOperatedByGlobale != 'undefined' && GEP.isOperatedByGlobale !== false) {
                $('.disable_globale').each(function () {
                    if (!$(this).hasClass('disabled')) {
                        $(this).addClass('disabled');
                    }
                });

                $('.no_stock_entrepot').each(function () {
                    $(this).show();
                });
            }

            if (typeof GEM_Services != "undefined") {
                try {
                    isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
                } catch (e) {
                    isOperatedByGlobalE = false;
                }
            }
        }
    }

    /* Placer Tableau livraison dans l'article respective */
    if ($('body.order_cgv').length) {
        $('#cgv_livraison').insertBefore("body.order_cgv .site_contents_wrapper .content_sat .content_inside strong:nth-of-type(5)");
    }

    /*/ Change Top left menu position/*/
    if ($('.menu_item.nouveautes').length) {
        $('.menu_item.nouveautes').insertBefore("#categ_femme");
    }

    if ($('body.search').length) {
        $('body.search #filters_form > .btn_container').insertBefore('body.search ul.filters + .btn_container');
    }

    /* Page cart */
    if ($('body.cart.step_1').length) {
        $("body.cart.step_1 .order_recap .bill_line.shipping_information").insertAfter("body.cart.step_1 .order_recap .order_total.subtotal");

        // Check if the unit value upselling_price is 0
        if ($('#threshold_fdp_txt .unit').text() == '0') {
            $('.upselling.price').css('display', 'none');
        }

        if ($('.cart.step_1 .expressAlertStock .bloc_add_alert_form').length) {
            $('.expressAlertStock .bloc_add_alert_form .w-input-container .w-input-element').attr('placeholder', Translator.translate('selection_mail'));
            $('.cart.step_1 .expressAlertStock .bloc_add_alert_form').prepend('<span class="alert_stock_wish">'+ Translator.translate("receive_alert") +'</span>');
            $('.cart.step_1 .expressAlertStock').find('.alert_return').appendTo('.bloc_add_alert_confirmation');
            $('.cart.step_1 .expressAlertStock').find('.bloc_add_alert_erreur').appendTo('.bloc_add_alert_form');
        }

        //Hide number in cta valider ma commande on page panier
        $('body.cart.step_1 #cart_total #btn_cmd_valid span').contents().filter(function() {
            return this.nodeType === Node.TEXT_NODE && $.trim(this.nodeValue).match(/^\d+$/);
        }).each(function() {
            $(this).wrap('<span style="display: none"></span>') //if you want to hide instead of remove
        });
    }

    /* Page livraision */
    if ($('body.cart.step_2').length) {
        $("body.cart.step_2 .order_recap .bill_line.shipping_information").insertAfter("body.cart.step_2 .order_recap .order_total.subtotal");
    }

    if ($('#rayonSwiper').length) {
        var rayonSwiper = new Swiper('#rayonSwiper.swiper-container', {
            slidesPerView: 3.75
        });
    }

    // Function pour gérer le bouton En savoir plus sur les pages adv
    $('.btn_see_text span').on('click', function () {

        if ($(this).parents('.cms-page-column').hasClass('see_less')) {
            $(this).parents('.cms-page-column').removeClass('see_less').addClass('see_more');
        } else {
            $(this).parents('.cms-page-column').removeClass('see_more').addClass('see_less');
        }
    });

    if ($('body.checkout').length) {
        $("body.checkout #paiement_3Times_cofidis").prepend("<div class='squareBloc'>");
        setTimeout(function() {
            $("body.checkout #paiement_3Times_cofidis .squareBloc").on("click", function(){
                $("body.checkout #paiement_3Times_cofidis p.bank_subtitle").click();
            });
        }, 200);
    }

    // Pour capter le enter lors de la gestion m'avertir de sa disponibilité
    $('#send_mail_alert_stock').on('keypress', function(e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            sendMailAlertStock('stock')
        }
    });

    setTimeout(() => {
        // Move TG Panier & Questions fréquentes before .container_other_information
        $('.cart .basket_gondole, .cart .bloc_question, .cart #container_other_information').wrapAll( '<div id="movedInfo"></div>' );
        $( '.cart .push_container.pushBasketWrapper' ).prependTo( '#movedInfo' );
        setTimeout(() => {
            $('#movedInfo').insertBefore('#copyright');
        }, 100);
    }, 300 );

    // Remove bloc_question as its already duplicated at the bottom of the page
    window.addEventListener("wishToBasket_complete", function (event) {
        $('#right_col_sticky .bloc_question').remove();
    });

    if ($('body').hasClass('cart') && $('body').hasClass('step_1')) {
        let originalBasketToWish = window.basketToWish;

        window.basketToWish = function () {
            if (typeof originalBasketToWish === 'function') {
                originalBasketToWish.apply(this, arguments);
                popupBasketToWish();
            }

            setTimeout(function () {
                if ($('.toggleCartTypeBtn.wishlist').length === 0) {
                    location.reload();
                }
            }, 500);
        };
    }

    $("#ctn-wrappers #filters_form_submit").on( "click", function() {
        setTimeout(function() {
            toggleMenu('filters', 'right');
        }, 800);
    });

    $("#ctn-wrappers .form_submit .button.cancel").on( "click", function() {
        $("#filters_form_submit span").html(Translator.translate("apply_item_filters"));
    });
});

function updateDisplayInventory(has_stock, hide_bookinstore, date_arrivage) {
    onLoadProductForm(has_stock, hide_bookinstore, date_arrivage)
}

/**
 * Displays the list of commands according to type
 *
 * @param {string} elementID
 * @param {string} type
 * @param {string} parentPath
 * @param {string|int|boolean} reload
 *
 * @returns {void}
 */
function show_orders_by_type(elementID, type, parentPath, reload) {
    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {
        str_year = '&year=' + year;
    }

    if (reload !== undefined) {
        str_reload = '&reload=true';
    }

    var i = 1;
    while (document.getElementById('onglet_' + i + '_mid')) {
        if (type == i) {
            $("#onglet_" + i + "_left").attr('class', 'left-on');
            $("#onglet_" + i + "_mid").attr('class', 'mid-on');
            $("#onglet_" + i + "_right").attr('class', 'right-on');
        } else {
            $("#onglet_" + i + "_left").attr('class', 'left-off');
            $("#onglet_" + i + "_mid").attr('class', 'mid-off');
            $("#onglet_" + i + "_right").attr('class', 'right-off');
        }
        i++;
    }

    var container = document.getElementById(elementID);

    if (container) {
        var response = ajax_file(
            parentPath + create_link('ajax_account_orders_by_type') + '?type=' + type + str_year + str_reload
        );

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {
                $(container).html(response);
            }
            return true;
        } else {
            container.innerHTML = '';
            return false;
        }
    } else {
        return false;
    }
}

function onAttrSelect(idProduit) {
    var form = document.getElementById('item_add_form'),
        colors = form.itm_colour,
        sizes = form.itm_size,
        quantities = form.itm_quantity,
        color, size;

    if (this.value) {
        this.classList.remove('error');
    }

    if (typeof btnAddToCart == 'undefined') {
        var btnAddToCart = document.getElementById("button_add");
    }

    color = (colors && colors.selectedIndex !== -1) ? colors.value : 'none';
    if (color !== 'none') {
        updateColor.call(colors.options[colors.selectedIndex]);
        if (this !== window && this.classList.contains('color')) {
            setAvailableSizes.call(this);
        }
    }

    size = (sizes !== undefined && sizes.selectedIndex !== -1) ? sizes.value : 'none';
    if (sizes !== undefined) {
        var nbsize = sizes.length === undefined ? 0 : sizes.length;
        var sizeValue = (size && sizes[sizes.selectedIndex] !== undefined) ? sizes[sizes.selectedIndex].text : 0;
        if (size && nbsize > 1 && sizeValue !== undefined) {
            //mise à jour du DataLayer
            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    'event': 'changeSize',
                    'produit': {
                        'size_value': sizeValue,
                    }
                });
                if (window.dataLayer[0].product) {
                    window.dataLayer[0].product.size = ($(this).attr('data-nom'));
                }
            }
        }

        var idProduitSize = (sizes && sizes.selectedIndex !== -1 && typeof sizes.options != 'undefined') ? sizes.options[sizes.selectedIndex].dataset.produit_id : idProduit;

        if (idProduitSize > 0) {
            idProduit = idProduitSize;
        }
    }

    if (quantities) {
        $.ajax({
            url: path_relative_root + create_link('ajax_liste_quantity'),
            type: 'get',
            async: false, // Should be async but would then need a loader so...
            dataType: 'json',
            data: { idprod: idProduit, idcouleur: color, idtaille: size, json: '1' },
            success: function(res) {
                // Add event to datalayer
                if (res.dataLayer_object && window.dataLayer !== undefined) {
                    window.dataLayer.push(res.dataLayer_object);
                }

                var lastPiecesEclatBlock = $('#content_product #lastPiecesEclat');
                var noStockEclat = $('#zoom_container .noStockEclat');

                /*
                    It can be useful when you need to display an eclat to let the customer know that the product isn't available at the moment:
                    - If the eclat exists, add a class depending on the availabality of the product for the selected size
                    - If my class is present on the element, I can display my eclat using style on the front office
                    (be sure to add your eclat in the view file depending on the project you're working on)
                */
                var nostock = false;

                if (res.disable_addtocart == true && ((typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined && GEP.isOperatedByGlobale !== false) || (typeof GEM_Services != 'undefined' && typeof  GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' &&  GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) || (typeof GEP  == 'undefined' && typeof res.is_sfs !== "undefined" && res.is_sfs == false))){
                    try  {
                        if (typeof GEM_Services !== "undefined") {
                            var isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
                        } else if (typeof GEP  !== 'undefined' && GEP.isOperatedByGlobale !== undefined &&  GEP.isOperatedByGlobale !== false) {
                            var isOperatedByGlobalE = GEP.isOperatedByGlobale;
                        }
                    } catch (e) {
                        var isOperatedByGlobalE = false;
                    }

                    if (isOperatedByGlobalE || (typeof GEP === 'undefined' && typeof GEM_Services != "undefined" && typeof res.is_sfs !== "undefined" && res.is_sfs == false)) {
                        res.stock_tampon = 0;
                        nostock = true;
                    }
                }

                // If no_stock eclat exists
                if (noStockEclat.length > 0 || nostock) {
                    if (size != "") {
                        // If size is not empty == a real size has been selected in the FP select box
                        if (parseInt(res.stock_tampon) == 0) {
                            // If theres no stock on this size and the item hasn't the no_stock class yet
                            if (!$('#zoom_container').hasClass('size_no_stock')) {
                                $('#zoom_container').addClass('size_no_stock');
                            }
                        } else {
                            // If there's stock but the no_stock class was already added from a previous size selection
                            if ($('#zoom_container').hasClass('size_no_stock')) {
                                $('#zoom_container').removeClass('size_no_stock');
                            }
                        }
                    } else {
                        // If the user hasn't selected a real size but the no_stock class was already added from a previous size selection
                        if ($('#zoom_container').hasClass('size_no_stock')) {
                            $('#zoom_container').removeClass('size_no_stock');
                        }
                    }
                }

                // If last pieces eclat exists
                if ((lastPiecesEclatBlock.length > 0) && (alerte_last_stock != undefined) && !nostock) {
                    if (parseInt(res.stock_tampon) <= parseInt(alerte_last_stock) && parseInt(res.stock_tampon) !== 0) {
                        lastPiecesEclatBlock.addClass('is-visible');
                    } else {
                        lastPiecesEclatBlock.removeClass('is-visible');
                    }
                }

                var content = res.content;

                quantities.parentElement.innerHTML = content.trim();

                var blocAlertStock = $("#bloc_add_alert");
                if (size != '') {
                    result = $($('#qteProd option:selected'), content);

                    /**
                     * Flag alert stock
                     * If enable, bloc_add_alert exist in DOM
                     * If disabled, bloc_add_alert don't exist in DOM
                     */
                    if (res.stock_tampon == 0) {
                        if (blocAlertStock.length > 0 ) {
                            $("#bloc_size_not_available_" + idProduit).hide();
                            $("#bloc_add_alert").hide();
                            if ($("#bloc_another_basket").length > 0 && res.stock_quantite > 0 && res.stock_tampon == 0) {
                                onAttrDisableAddCart(false);
                                $(".stock_check").hide();
                                $("#bloc_another_basket").show();
                                btnAddToCart.disabled = false;
                            } else {
                                if (result.hasClass('no_stock') || nostock) {
                                    onAttrDisableAddCart(false, true);
                                    $("#bloc_add_alert").show();
                                    $("#add_basket_wrapper").hide();
                                    if (!force_eresa && hide_bookinstore == 1)  {
                                        $("#link_book_in_store").hide();
                                    }
                                    $(".stock_check").hide();
                                    btnAddToCart.disabled = true;
                                } else {
                                    if (typeof(use_preorder_with_alert) == 'undefined' || (typeof(use_preorder_with_alert) != 'undefined' && res.produit_precommande != 'oui')) {
                                        onAttrEnableAddCart(false);
                                    } else {
                                        onAttrEnableAddCart();
                                    }
                                    $("#link_book_in_store").show();
                                    $(".stock_check").show();
                                }

                                if ($("#bloc_another_basket").length > 0) {
                                    $("#bloc_another_basket").hide();
                                }
                            }
                        } else {
                            onAttrDisableAddCart(false, false);
                            $("#add_basket_wrapper").hide();
                            $("#link_book_in_store").hide();
                            $("#bloc_size_not_available_" + idProduit).show();
                            btnAddToCart.disabled = true;
                        }
                    } else {
                        onAttrEnableAddCart();
                        $("#link_book_in_store").show();
                        $("#add_basket_wrapper").show();
                        $("#bloc_add_alert").hide();
                        $("#bloc_size_not_available_" + idProduit).hide();
                        btnAddToCart.disabled = false;
                    }

                } else if (size == "" && res.disable_addtocart == true) {
                    onAttrDisableAddCart(true, false);
                    btnAddToCart.disabled = true;
                }

                checkProductInWishlist();

                // Check if globale delivery country
                if (res.disable_addtocart == true) {
                    if (isOperatedByGlobalE || (!isOperatedByGlobalE && typeof res.is_sfs !== "undefined" && res.is_sfs == false)) {
                        $("#add_basket_wrapper").hide();
                        $(".bloc_add_alert").show();
                        btnAddToCart.disabled = true;
                        if ($("#bloc_another_basket").length > 0) {
                            $("#bloc_another_basket").hide();
                        }
                    }

                    if (typeof res.is_personalize !== "undefined" && res.is_personalize == true) {
                        $(".btn_personalize").hide();
                    } else {
                        $(".btn_personalize").show();
                    }
                }
            }
        });
        checkSizeWpos();
    }
}

function checkSizeWpos() {
    // WPOS Exclusive - Get if product is available in store use in WPOS
    var idStore = getCookie('wshopStoreId');
    var idProduct = getCookie('p_id');
    if (idStore != undefined && idStore > 0) {
        var color = $("#prod_couleur").val();
        var size = $("#prod_taille").val();
        getProductInfoByStore(idProduct, color, size, idStore)
    }
}

function changeQte(type) {
    var types = ['plus', 'less'], itemId, loaderQte, qtty, prodId;

    if (types.includes(type)) {
        itemId = $(this).find("input[name='item_id']").val();
        loaderQte = $(this).find('#qte_loader_' + itemId);
        qtty = parseFloat($(this).find("input[name='selected_qte']").val());
        prodId = parseFloat($(this).find("input[name='item_prod_id']").val());

        this.qtty_select_wrap.style.display = 'none';
        loaderQte.css('display', 'block');

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: 'post',
            data: {
                action: 'del',
                panierId: itemId,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (typeof wShop !== "undefined" && wShop.$refs.wCouponForm !== "undefined") {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                $('#cart_btn').data('cart', response);

                updateCartElements();
                cart_switch_view();

                if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'less') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "remove_from_cart");
                }

                if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'plus') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "add_to_cart");
                }

                btnConfirmSticky();
            }
        });
    }
}

function cart_switch_view() {
    /* Switch between cart and wishlist view in basket */
    if ($('.toggleCartTypeBtn').length) {

        let toggleCart = $('.toggleCartTypeBtn');
        let cartContent = $('.cart_main_table.basket');
        let wishContent = $('.cart_main_table.wishlist');
        let cartSummary = $('#tunnel_right_col');

        toggleCart.on('click', function () {

            toggleCart.removeClass('actif');
            $(this).addClass('actif');
            $('.cart_main_table').removeClass('displayed');

            if ($(this).hasClass('basket')) {

                cartContent.addClass('displayed');
                cartSummary.removeClass('noDisplay');
            } else {

                wishContent.addClass('displayed');
                cartSummary.addClass('noDisplay');
            }
        });
    }
}

$(document).on('click tap touch', '.toggle_modal_actions', function () {
    $(this).parents('.product_line_basket').find('.cart_item_actions').slideDown('slow');

    var modalCartItemActions = $(this).parents('.product_line_basket').find('.cart_item_actions');

    if (!modalCartItemActions.find('.cart_item_actions_close_container').length) {
        var cartItemActionsClose = $(this).parents('.product_line_basket').find('.cart_item_actions_close');
        var cartItemActionsCloseContainer = $('<div>', { class: 'cart_item_actions_close_container' });

        cartItemActionsClose.appendTo(cartItemActionsCloseContainer);
        cartItemActionsCloseContainer.appendTo(modalCartItemActions);
    }

    if (!$('#shade').hasClass('visible')) {
        $('#shade').addClass('visible');
        $('body').addClass('noscroll');
    }
});

function hideModalAction(evt) {
    var shade = $('#shade');
    var modal = $(".cart_item_actions");

    if (typeof evt !== "undefined") evt.preventDefault();

    shade.off('touchend', modBoxClose).removeClass('visible');
    $('.toggle_modal_actions').css('display', 'block')
    modal.slideUp();
    $('body').removeClass('noscroll');

    cartSwipeGondole();
}

function cartSwipeGondole() {
    if ($('body.step_1').length && (!$('.gondole_cart_wrapper').length)) {
        setTimeout(function () {
            let title_gondole_cart = $('.title_gondole');
            let basket_gondole_cart = $('.basket_gondole');

            if (basket_gondole_cart.length) {
                basket_gondole_cart.fadeIn();

                $('.basket_gondole .swiper-slide').each(function () {
                    $(this).replaceWith(function () {
                        return $('<div>', {
                            class: $(this).attr('class'),
                            html: $(this).html()
                        });
                    });
                });

                basket_gondole_cart.insertAfter('#cart_total');

                $('.basket_gondole .swiper-slide').wrapAll('<div class="gondole_cart_wrapper"><div class="swiper swiper-container basket_gondole_swiper"><div class="swiper-wrapper"></div></div></div>');
                $('<div class="swiper-pagination"></div>').appendTo('.basket_gondole_swiper');
                $('<div class="swiper-button-next"></div><div class="swiper-button-prev"></div>').appendTo('.basket_gondole_swiper');

                setTimeout(function () {
                    if ($('.basket_gondole_swiper .swiper-slide').length > 1) {
                        var loopStatus = false;

                        if ($('.basket_gondole_swiper .swiper-slide').length > 2) {
                            loopStatus = true;
                        }

                        new Swiper('.basket_gondole_swiper', {
                            slidesPerView: 2,
                            spaceBetween: 12,
                            loop: loopStatus,
                            prevButton: '.swiper-button-prev',
                            nextButton: '.swiper-button-next',
                            pagination: '.swiper-pagination',
                            paginationType: 'bullets',
                            paginationClickable: true,
                        });
                    }
                }, 200);
            }
        }, 200);
    }
}

function cartExistInWishlist() {
    if ($('.cart_main_table.wishlist').length && $('.cart_main_table.basket').length) {
        var wishlistProducts = [];

        $('.cart_main_table.wishlist .cart_product_line').each(function() {
            var product = $(this);
            var productId = product.find('a.wishToBasket').attr('data-produit-id');
            var sizeId = product.find('a.wishToBasket').attr('data-size-id');
            var colorId = product.find('a.wishToBasket').attr('data-color-id');

            wishlistProducts.push({ productId, sizeId, colorId, element: product });
        });

        $('.cart_main_table.basket .cart_product_line').each(function() {
            var basketProduct = $(this);
            var basketProductId = basketProduct.find('a.product_towish').attr('data-productid');
            var basketSizeId = basketProduct.find('a.product_towish').attr('data-sizeid');
            var basketColorId = basketProduct.find('a.product_towish').attr('data-colorid');

            wishlistProducts.forEach(function(wishlistProduct) {
                if (wishlistProduct.productId === basketProductId &&
                    wishlistProduct.sizeId === basketSizeId &&
                    wishlistProduct.colorId === basketColorId) {

                    basketProduct.find('.basketToWishlist').addClass('matchedWishlist');
                }
            });
        });
    }
}

function removeFromBasketExtra() {
    $('#right_col_sticky .bloc_question').remove();
}

function changeRelay(elem, relayId, country = '') {
    let relayType = (typeof ($(elem).data('relay-type')) !== 'undefined' ? $(elem).data('relay-type') : 0);
    $('#livr_relais').val(relayId);
    $('#livr_dom').val(relayId);
    $('#relay_country').val(country);
    $('#relay_type').val(relayType);
    let relayName = $('#relay-' + relayId + ' .kpname').html();
    let relayAddress = $('#relay-' + relayId + ' [name="relay-address"]').val();
    $(".delivery_choice.active").find('.shop_name').html(relayName);
    $(".delivery_choice.active").find('.adresse').html(relayAddress);

    if ($('#relay-' + relayId + ' .clickandgo_schedule').length) {
        let relaySchedule = $('#relay-' + relayId + ' .clickandgo_schedule').html();
        $(".delivery_choice.active").find('.transporteur_clickandgo_schedule').html(relaySchedule);
    }

    let relayLabel = $(elem).data('conveyor-type') == 'relay' ? 'livraison_dans_relais' : 'livraison_en_magasin';

    $('#delivery_address_recap_other .addr_label').html(Translator.translate(relayLabel));
    $('#delivery_address_recap_other .full_name').html(relayName);
    $('#delivery_address_recap_other .full_address').html(relayAddress);

    $('#shade').length ? $('#shade').removeClass('actif') : $('#shad').removeClass('actif');
    $('.delivery_lightbox ').removeClass('actif active');
    $('body').removeClass('noscroll');

    // close lightbox in click on 'choisir ce magasin' lightbox basket
    $('#shade').removeClass('relay_lightbox').css('display','none');
}

// alert stock wishlist basket
function openAlerteStock() {
    $('.cart.step_1 .expressAlertStock .alert_stock').hide();
    $('.cart.step_1 .expressAlertStock .bloc_add_alert_form').fadeIn();
    $('#shade').addClass('visible');

    $('.modal_shade.visible').on('click tap touchstart', function() {
        $('.expressAlertStock .bloc_add_alert_form').fadeOut();
        $('.expressAlertStock .alert_stock').css('display', 'block');
        $('.bloc_add_alert_confirmation').css('display', 'none');
        $(this).removeClass('visible');
    });
}

// alert stock wishlist basket
function closeAlerteStockWishlist() {
    $('.cart.step_1 .expressAlertStock .bloc_add_alert_confirmation').hide();
    $('.cart.step_1 .expressAlertStock .alert_return').hide();
    $('.cart.step_1 .expressAlertStock .alert_stock').fadeIn();
    $('.cart.step_1 .expressAlertStock .expressAlertStock').fadeIn();
    $('#shade').removeClass('visible');
}

function sendMailAlertStock(type_alert, form, itm_rr_id) {
    var suffix = form.elements['suff'].value ? '_' + form.elements['suff'].value : '',
        mail_alerte_stock = form.elements['mail_alerte_stock'],
        email = mail_alerte_stock.value,
        produit_id = form.elements['produit_id'].value,
        couleur_id = form.elements['couleurProd'].value,
        taille_id = form.elements['itm_size'].value;

    $verif_mail = checkForm('mail', mail_alerte_stock, 'login_email');

    if (email == '' || !$verif_mail) {
        alert(Translator.translate('js_error_mail'));
    } else if (!(couleur_id > 0)) {
        alert(Translator.translate('js_error_couleur'));
    } else if (!(taille_id > 0)) {
        alert(Translator.translate('js_error_size'));
    } else {

        var isOperatedByGlobalE = false;
        if (typeof GEM_Services != "undefined"  && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != "undefined") {
            isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
        } else if (typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined) {
            isOperatedByGlobalE = GEP.isOperatedByGlobale;
        }

        if (isOperatedByGlobalE === true && !enable_gbe_sfs) {
            type_alert = "stock_gbe";
        }

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: email,
                produit_id: produit_id,
                taille_id: taille_id,
                couleur_id: couleur_id,
                type_alert: type_alert
            },
            success: function(response) {
                $('.alert_stock').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_erreur').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_form').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_confirmation').show();
                $('.cart.step_1 .expressAlertStock#bloc_add_alert' + suffix + ' .alert_return').show();
                // if successful let's send data to GTM
                var dataLayer = window.dataLayer || [];
                dataLayer.push({
                    "event": "stockAlert",
                });
            },
            fail: function() {
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_confirmation').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_erreur').show();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_form').show();

            }
        });
    }
}

function btnConfirmSticky() {
    const $formPanierContainer = $('.formPanier_container');
    const $cartTotal = $( '.bill_line.total' ); // Targeting the cart total container
    const stickyClass = 'sticky';

    if (!$cartTotal.length) {
        return;
    }

    // Function to check visibility
    function checkVisibility() {
        const cartTotalOffsetTop = $cartTotal.offset().top; // Get the top offset of #cart_total
        const cartTotalHeight = $cartTotal.outerHeight(); // Get the height of #cart_total
        const cartTotalBottom = cartTotalOffsetTop + cartTotalHeight; // Calculate the bottom of #cart_total
        const windowScrollTop = $(window).scrollTop(); // Current scroll position
        const windowHeight = window.innerHeight; // Using window.innerHeight to fix iOS blurry area issue
        const windowBottom = windowScrollTop + windowHeight - 100;

        // Only add the sticky class when the entire #cart_total is completely out of the viewport
        if (cartTotalBottom < windowScrollTop || cartTotalOffsetTop > windowBottom) {
            // Add the sticky class if #cart_total has completely scrolled out of view
            $formPanierContainer.addClass(stickyClass);
            $formPanierContainer.find('#btn_cmd_valid span.separator').text('\u00A0|\u00A0');
        } else {
            // Remove sticky class if any part of #cart_total is still in view
            $formPanierContainer.removeClass(stickyClass);
        }
    }

    // Run on scroll
    $(window).on('scroll', checkVisibility);

    // Run on page load
    checkVisibility();
}

// [SW-16857] Hide shipping time until 31/12/2024@00:01
if (new Date() < new Date('2024-12-31T00:01:00')) {
    document.addEventListener('DOMContentLoaded', function() {
        // if the body has a .product_page class, add a style tag to hide the shipping time
        if (document.querySelector('body.product_page')) {
            var shippingTime = document.querySelector('.stock_check');

            // create style tag to override any JS/in-line styles
            var style = document.createElement('style');
            style.innerHTML = '.stock_check { display: none !important; }';
            document.head.appendChild(style);
        }
    });
}
